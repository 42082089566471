import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileNavbarShowcase from './MobileNavbarShowcase';
import loginlogo from "../resources/loginlogo.png";
import formbanner from "../resources/formbannermb.png";

const frm = {
    backgroundImage: `url(${formbanner})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative'

};

const MobileLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if the password is correct
        if (password !== 'correctpassword') {
            setErrorMessage('Incorrect password. Please try again.');
            return;
        }

        // Clear error message if password is correct
        setErrorMessage('');

        // Reset form fields
        setUsername('');
        setPassword('');

        // Show success message (replace with your preferred notification method)
        alert("Login successful!");
    };

    return (
        <div className='w-full h-[929px] bg-[#050505] mx-auto z-[10] overflow-x-hidden'>
            <div className='z-[15] relative'>
                <MobileNavbarShowcase />
            </div>

            <div className=''>
                <Link to="/landing1">
                    <div className='flex w-[39%] mx-auto pt-[22px] z-[5]'>
                        <img src={loginlogo} alt="logo" />
                    </div>
                </Link>
                <div className='mt-[22px]'>
                    <div className='text-white text-thin text-[15px] font-Metropolis w-[85%] mx-auto text-center leading-[1.4rem]'>Get your hands on<br></br><span>the <span className='font-semibold'>Makro Exclusives</span></span></div>
                </div>
                <div className='text-[11px] text-white text-center w-[89%] mx-auto mt-[12px]'>
                    Indulge in Makro's top curated styles. Request access, and we'll swiftly provide your credentials. Immerse yourself in unparalleled craftsmanship and exquisite design.
                </div>

                <div className='form h-[577px] w-[89%] mx-auto mt-22px] z-[10] mt-[12px] overflow-x-hidden' style={frm}>
                    <div className='absolute inset-0 flex flex-col justify-start items-center'>
                        <div className='text-[27.31px] font-medium font-Metropolis text-center text-white mt-20'>
                            Login to Makro
                        </div>
                        <div className='font-Metropolis text-[14px] text-center mt-[4px]  text-[#686868] font-medium'>Unlock Makro's Exclusive Style Experience.</div>

                        <form onSubmit={handleSubmit} className='mt-[39px]'>
                            <div>
                                <input
                                    type="text"
                                    placeholder='Username'
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className='w-[291px] h-[42px] bg-[#282828] rounded-[12px] text-left pl-[59px] font-Metropolis font-medium text-white'
                                />
                            </div>
                            <div className='mt-[24px]'>
                                <input
                                    type="password"
                                    placeholder='Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='w-[291px] h-[42px] bg-[#282828] rounded-[12px] pl-[59px] font-Metropolis font-medium text-white'
                                />
                            </div>
                            <div className='text-red-500 mt-2'>{errorMessage}</div>
                            <div className='flex flex-row mt-[42px]'>
                                <div className='flex flex-col w-[50%]  h-full text-left'>
                                    <div className="flex items-center ">
                                        <input type='checkbox' id='rememberMe' className='mr-2  bg-white' />
                                        <label htmlFor='rememberMe' className=' font-Metropolis text-[#636363] text-[11px]'>Remember Me</label>
                                    </div>
                                </div>
                                <div className='flex flex-col w-[50%] h-full text-right'>
                                    <Link to="/Forgotpasswordmb"><div className='text-[#636363] text-[11px]'>Forgot Password?</div></Link>  
                                </div>
                            </div>
                            <div className='mt-[42px]'>
                                <button type="submit" className='w-[121px] h-[32px] gradient-login rounded-[12px] text-[12px] font-Metropolis text-center'>Login</button>
                            </div>
                        </form>
                        <div>
                            <Link to="/Requestformmb"><div className='text-[11px] font-Metropolis text-center text-white mt-[38px]'><span className='text-[#757575]'>Not a member yet?</span> Request Access</div></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileLogin;