import React from 'react';
import { Link } from 'react-router-dom';
import home from "../resources/home.svg";
import homeblack from "../resources/homeblack.svg";
import crown from "../resources/crown.svg";
import product11of1 from "../resources/Product11of1.png";
import product11of2 from "../resources/Product11of2.png";
import product11of3 from "../resources/Product11of3.png";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import ExampleComponents from './FabricSlider';
const Product11 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
}, []);

const navigate = useNavigate();


const handleClick = () => {
  navigate('/Showcase#sectionId4')
};

    return (
        <div className='bg-black mt-[-74px] pt-[54px] '>
           {/*  Front Page */}
           <div className=" 2xl:block hidden md:hidden 3xl:hidden bg-black ml-[28px]">
             <div className="main-menu flex flex-row w-[94%] mx-auto h-auto gap-[44px] mt-[74px] 2xl:ml-[128px]">
               
         
    <ExampleComponents/>
               <div className="">
                <Link to="/" className="parentDiv ">
              <div>
                <img src={home} alt="Home" className="defaultImage " />
                <img src={homeblack} alt="Home Black" className="hoverImage" />
              </div>
            </Link>
           </div>
               
                <div className="">
                <div className="bg-[#202020] rounded-[30px] w-[25%] h-[100%] ease-in-out transition duration-1000 hover:bg-gradient-to-b hover:from-[#E7AC16] hover:to-[#FAD167]  w-[585px] h-[141px]">
              <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
                <img src={crown} alt="Home" className=" hover:text-white" />
                <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
              </div>
            </div>
                </div>
    
             </div>
    
             </div>
    
    
             <div className="medium w-[1440px] h-[222px]  md:block hidden 2xl:hidden">
             <div className="flex flex-row gap-[24px] w-[87%] mx-auto mt-[52px]">
               <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] ">
                <ExampleComponents/>
               </div>
    
               <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
                <Link  to="/">
               <div className="mt-[22px]">
                <img src={home} alt="Home" className="defaultImage " />
                <img src={homeblack} alt="Home Black" className="hoverImage" />
              </div>
              </Link>
               </div>
    
               <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
               <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
                <img src={crown} alt="Home" className=" hover:text-white" />
                <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
              </div>
               </div>
             </div>
         </div>
         
    
         <div className='overflow-hidden'>
     <div className="makebook w-[1782px] h-[222px] 3xl:block 2xl:hidden md:hidden ml-[52px]">
  <div className="flex flex-row gap-[24px] w-[97%] mx-auto mt-[52px]">
    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] ">
      <ExampleComponents />
    </div>

    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] bg-[#202020]">
    <Link  to="/">
           <div className="mt-[42px]">
            <img src={home} alt="Home" className="defaultImage " />
            <img src={homeblack} alt="Home Black" className="hoverImage" />
          </div>
          </Link>
    </div>

    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] bg-[#202020] ">
           <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
            <img src={crown} alt="Home" className=" hover:text-white" />
            <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
          </div>
           </div>
  </div>
</div>
</div>



   {/* Second Section */}
   <div className='flex flex-row w-[89%] mx-auto h-[790px] bg-black mt-[144px]'>
      <FontAwesomeIcon icon={faTimes} className='text-white text-3xl  cursor-pointer' onClick={handleClick} />
        <div className='flex flex-col w-[50%] h-[512px] bg-black'>
          <img src={product11of1} className=' mx-auto' />

        </div>
        <div className='flex flex-col w-[50%] h-[512px] bg-black'>
          <img src={product11of2} className=' mx-auto' />

        </div>

      </div>

    
                       {/* Third Section */}
    
              <div className=' '>
                 <div className='w-[1811px] h-[274px] design11 mx-auto w-[94%]'>
                        <div className='text-[38px] font-Kamerik 105 W00 Bold text-center pt-[84px] text-white pt-[24px]' >Contrast Two Toned Asymmetrical Hem Collared Shirt</div>
                    
                    <div className='text-[28px] font-Avenir w-[79%] mx-auto text-left mx-auto text-center text-white'>
                    Effortless Sophistication: The Gold and Cream Asymmetrical Shirt    </div>
                 </div>
              </div>
    
            
           {/* Fourth Section */}
           <div className='flex flex-row w-[89%] mx-auto h-[790px] bg-black mt-[74px]'>
                     <div className='flex flex-col w-[50%] h-[512px] mx-auto bg-black'>
                     <img src={product11of3} className='mx-auto mt-[-44px]'/>
    
                     </div>
                    
    
              </div>
           {/* Fourth Section */}
           
        <div className='flex flex-row mt-[77px] pb-[277px] bg-black 2xl:mt-[-37px] md:mt-[-177px] pb-[284px]'>
              <div className='flex flex-col w-[40%]  ml-[59px]'>
              <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center'>
      <span>Description</span>
      <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
    </div>
    
    <div className='text-[28px] font-AvenirRoman w-full text-white text-left mt-[35px]'> A chic Gold and Cream Asymmetrical shirt for girls, effortlessly crafting a bold style statement. Its unparalleled aesthetics defy explanation, commanding attention with the right styling.</div>
          </div>
              <div className='flex flex-col w-[40%] '>
              <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center ml-[88px]'>
      <span>Details</span>
      <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
    </div>
    <div className='text-[28px] font-AvenirRoman text-white text-left mt-[21px] ml-[88px]'>1. 100% Cotton</div>
    <div className='text-[28px] font-AvenirRoman text-white text-left ml-[88px]'>2. Woven Satin Fabric</div>
              </div>
    
        </div>
           
        </div>
      );
    };
export default Product11;