import React from 'react';
import thankyoubg1 from "../resources/tkbg.png";
import messageicon from "../resources/Icon.png";
import { Link } from 'react-router-dom';
const thankyoubg= {
    backgroundImage: `url(${thankyoubg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
};


const ThankYou = () => {
  return (
    <div className='w-full h-screen bg-black' style={thankyoubg}>
        <div className='flex item-center justify-center  pt-[300px]'>
        <img src={messageicon} />
        </div>
        <div className='text-center text-white font-Metropolis text-[44px] text-[#a7a7a7] font-thin leading-[3.4rem] mt-[48px]'>We have received your request, <br></br>
<span className='text-[#a7a7a7]'>You will <span className='text-white font-medium'>receive your credentials shortly.</span></span></div>
<div className='font-Metropolis font-light text-[30px] text-white mt-[34px]'>
  Till then, Keep Exploring Existing <span className='font-Metropolis font-light text-[30px] text-[#E3A90E]'><Link to="/Showcase">Show<span style={{ borderBottom: '2px solid #E3A90E' }}>case</span></Link></span>
</div>
    </div>
  );
};

export default ThankYou;