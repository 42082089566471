import packing from "../resources/packing.png";
import india from "../resources/india.png";
import workers from "../resources/workers.png";
import eec from "../resources/eec.png";
import disnep from "../resources/disnep.png";
import { useState } from "react";
import video from "../resources/video.png";
import { useEffect } from "react";
import map from "../resources/map.png";
import sedex from "../resources/sedex.png";
import year from "../resources/year.png";
import left from "../resources/left-arrow.png";

import number1 from "../resources/20k.svg";
import number2 from "../resources/80k.svg";
import number3 from "../resources/5M.svg";
import number4 from "../resources/300.svg";
import number5 from "../resources/6.svg";
import thousand from "../resources/50,000mb.svg";
import windmill from "../resources/windmill.png";
import email from "../resources/Email.svg";
import phone from "../resources/call.svg";
import arrow from "../resources/arrow.svg";
import classbanner from "../resources/classbanner.png";
import stitch from "../resources/stitch.png";
import quotes from "../resources/founder.png";
import ceo from "../resources/ceo_new.png";
// import quotes from "../resources/quotes.png";
import fotterlogo from "../resources/footelogo.png";
import { Button, TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useRef } from "react";
import bglanding from "../resources/mobilebanner.png";
import mbpacking from "../resources/packing-mobile.png";
import indiabg from "../resources/india-mb.svg";
import wokersmb from "../resources/workersmb.png";
import disnepmb from "../resources/disnepmb.png";
import sedexpmb from "../resources/sedexmb.png";
import clientsmb from "../resources/clientsmb.png";
import mb1 from "../resources/20kmb.svg";
import mb2 from "../resources/80kmb.svg";
import mb3 from "../resources/300k.svg";
import mb4 from "../resources/5Mb.svg"
import mb5 from "../resources/6mb.svg"
import founded from "../resources/foundedmb.svg"
import testi1 from "../resources/testimb.png"
import windmb from "../resources/windmillmb.png"
import certicatemb from "../resources/disnepmb.png"
import footermb from "../resources/footermb.svg"
import clientmb1 from "../resources/clientmb1.png"
import clientmb2 from "../resources/clientmb2.png"
import clientmb3 from "../resources/clientmb3.png"
import clientmb4 from "../resources/clientmb4.png"
import clientmb5 from "../resources/clientmb5.png"
import clientmb7 from "../resources/clientmb7.png"
import clientmbsix from "../resources/clientmbsix.png"
import mapmb from "../resources/mapmb.png";
import playiconmb from "../resources/playiconmb.svg";
import certifibg1 from "../resources/certifibg1.png";
import ftrbanner1 from "../resources/fotterbanner.png";
import clients from "../resources/clientsimg.png";
import React from "react";
import Navbar1 from "./navbar";
import Navbar2 from "./MediumNavbar";
import MobileNavbar from "./MobileNavbar";
import foundermb from "../resources/foundericon.png";
import vpmb from "../resources/vpicon_new.png";
import MyCarousel from "./MultiCarosul";
import PhotoCarousel from "./MultiCarosul";
import MacBookNavBar from "./MacbookNavbar";
import ProductSection from "./MultiCarosul";
import SliderContainer from "./SliderContainer";
import ProductCarousel from "./SliderContainer";
import Carousel from "./SliderContainer";
import Prdouct from "./SliderContainer";
import Products from "./SliderContainer";
import IronSlider from "./SlideShow";
import MobileNavbarShowcase from "./MobileNavbarShowcase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import VimeoVideo from "./YoutubePlayer";
import emailjs from '@emailjs/browser';

const Premises = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff", // White color for primary elements
      },
      text: {
        primary: "#B7B7B7",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInput-underline:before': {
              borderBottomColor: 'rgba(255, 255, 255, 0.5)', // normal border color with 50% opacity
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: 'rgba(255, 255, 255, 0.5)', // normal border color with 50% opacity
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'rgba(255, 255, 255, 0.5)', // normal border color with 50% opacity
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#B7B7B7",
          },
        },
      },
    },
  });


  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Check if there's a hash in the URL indicating a specific section
    const hash = window.location.hash;
    if (hash) {
      // If there's a hash, scroll to the corresponding section
      const sectionId = hash.substring(1); // Remove the leading '#'
      scrollToSection(sectionId);
    } else {
      // If there's no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    // Clean up function
    return () => {
      // You can include any cleanup logic here if needed
    };
  }, []);


    const bgbanner = {
      backgroundImage: `url(${bglanding})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };


  const founder = {
    backgroundImage: `url(${foundermb})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const vp = {
    backgroundImage: `url(${vpmb})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };



  const certifibg = {
    backgroundImage: `url(${certifibg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      console.log("Scrolling to section:", sectionId);
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error("Section not found:", sectionId);
    }
  }


 
  



  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    emailjs.sendForm('service_obnwe27', 'template_uba0nfm', form.current, 'I4lBD175Tz08-E5cr')
      .then(
        () => {
          console.log('SUCCESS!');
          setShowSuccessMessage(true);
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  const videoRef = useRef(null);

  useEffect(() => {
    // Check if the video element exists
    if (videoRef.current) {
      // Play the video
      videoRef.current.play()
        .then(() => {
          // Video playback started successfully
          console.log('Video playback started successfully');
        })
        .catch(error => {
          // Video playback failed
          console.error('Video playback failed:', error);
        });
    }
  }, []); // Run only once on component mount


  return (
    <div className="main">
      <div className="h-auto w-full flex flex-col items-center justify-center 2xl:block md:block hidden sm:hidden ">
        {/* FIRST LANDING SECTION */}

        <div className="2xl:block md:block">

          <div className="fixed z-40 md:hidden 2xl:block ">
            <Navbar1 />
          </div>

          <div className="fixed z-40 md:block 2xl:hidden ">
            <Navbar2 />
          </div>


          <div className="fixed z-40 mackbook:block 2xl:hidden  md:hidden">
            <MacBookNavBar />
          </div>
          <div
            className="h-[80vh] sm:h-screen w-full text-black sm:items-start  sm:justify-end items-center justify-center flex flex-col sm:pl-[50px] pb-[50px] gap-[40px] realtive "
            style={{
              backgroundImage: `url(${stitch})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              cursor: "pointer",
            }}
          >
            <div className="2xl:block md:block">
              <div id="home" className="bg-transparent h-[44px] ">

              </div>
              <div className="mt-auto mb-auto ">
                <div className=" ">
                  <div className="2xl:text-[83px] md:text-[59px] font-semibold text-white font-Urbanist text-center sm:text-left 2xl:leading-[6.5rem] md:leading-[4.2rem]  2xl:ml-[65px] 2xl:mt-[492px]">
                    Elegant Details, Enduring Beauty.
                  </div>
                  <div className="font-Urbanist font-Regular 2xl:text-[57px] 2xl:ml-[65px] md:mr-[72px] md:text-[40px] text-white text-left mt-[-8px]">Discover the Makro Difference</div>
                  <div className="text-white text-[8px] md:text-[15px] 2xl:text-[19px] w-full md:w-[35%] 2xl:w-[32%] text-left flex 2xl:ml-[65px] font-MontserratLight">
                    At Makro Fashions, we redefine the art of garment manufacturing,
                    blending modern aesthetics with timeless elegance. Our commitment to
                    intricate patterns and unique imported fabrics sets us apart in an
                    industry driven by mediocrity.
                  </div>
                </div>

                <div className="flex flex-row gap-[30px] items-center justify-center  2xl:ml-[-1294px] md:ml-[-844px] mt-[22px] px-[24px] py-[24px]">
                  {/* <div className="bg-white rounded-[40px] hidden sm:flex">
        <Button
          variant="contained"
          sx={{
            borderRadius: "30px",
            backgroundColor: "transparent",
            backdropFilter: "blur(100px)",
          }}
        >
          Schedule a Meeting
        </Button>
      </div> */}

                  <button className="font-Avenir btn-schedule text-[18px] text-white 2xl:ml-[57px] md:ml-[11px] mt-[-22px]" onClick={() => scrollToSection('contact')}>Schedule a Meeting</button>
                  <div className="text-white text-[10px] sm:text-[18px] underline mt-[-22px]" onClick={() => scrollToSection('infrastructure')}>
                    view infrastructure panel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECOND HEADING SECTION */}
        <div className="h-auto w-full flex flex-col items-center justify-center gap-[30px] mt-[-32px]">
          <div className="w-full h-auto text-black 2xl:text-[93px] md:text-[63px] font-Urbanist font-semibold flex-col flex items-center justify-center mt-[125px]">
            <div>not just another</div>
            {/* <div className="bg-[#CBFB45] rounded-[100px] px-[20px] bg-opacity-45 leading-[7.4rem]">
              garment manufacturer
            </div> */}
            <div className="bg-[#CBFB45] bg-opacity-45 pl-[22px] pr-[22px] pb-[28px] pt-[12px] rounded-[99px] leading-[3.8rem] mt-[-14px]">
              garment manufacturer
            </div>
          </div>
          <div className="w-[50%] h-auto text-[#474747bf] 2xl:text-[20px] sm:text-[15px] font-avenir flex-col flex items-center justify-center text-center">
            <div>
              Elevating standards, crafting excellence. Makro Fashions redefines
              garment manufacturing with unmatched precision and exotic flair.
              Experience the difference today.
            </div>
          </div>
        </div>

        {/* THIRD BOXES SECTION */}
        <div className="2xl:h-[100vh] md:h-[900px] w-[54%] sm:w-[80%] flex flex-col sm:flex-row items-center justify-center my-[40px] sm:my-[100px] gap-[20px] 2xl:ml-[172px] md:ml-[142px]">
          <div className="h-[50%] sm:h-full w-[100%] sm:w-[50%] flex flex-col gap-[20px] ">
            <div className="w-full 2xl:h-[90%] md:h-[50%] flex items-center justify-center 2xl:mt-[-222px] md:mt-[-82px]">
              <img
                src={packing}
                alt="Foo img"
                className="object-contain rounded-[30px]"
              />
            </div>

            <div className="flex h-[80%] sm:h-[50%] items-center justify-center gap-[5px] sm:gap-[20px] 2xl:mt-[-142px] md:mt-[-87px] ">
              <div className="w-[50%] h-full flex flex-col items-center justify-center  gap-[5px] sm:gap-[20px]">
                <div className="2xl:w-[427px]  md:w-[265px] 2xl:h-[212px] md:h-[171px] 2xl:mt-[-137px] md:mt-[-77px] 2xl:ml-[-14px] md:ml-[-33px]  rounded-[30px] bg-[#F5F5F5] flex items-center justify-center">
                  <div className="text-[7px] sm:text-[12px] 2xl:text-[15px] md:text-[11px] text-center 2xl:w-[85%] md:w-[75%] font-MontserratLight">
                    Located in Tiruppur's pulsating centre, Makro Fashions
                    epitomises style, innovation, and quality, thriving amidst the
                    global apparel hub's vibrant energy.
                  </div>
                </div>
                <div className="2xl:w-[427px] 2xl:h-[50%] md:w-[265px] md:h-[171px]  2xl:ml-[-12px]  rounded-[30px] md:ml-[-29px]  bg-[#961919] flex items-start justify-center flex-col px-[10px] py-[5px]  gap-[5px] sm:gap-[10px]">
                  <div className="text-white text-[20px] 2xl:text-[38px] md:text-[28px] font-Urbanist font-extrabold 2xl:ml-[24px] 2xl:mt-[-18px] md:mt-[-11px] md:ml-[12px] ">
                    Vision
                  </div>
                  <div className=" 2xl:text-[12px] md:text-[11px] w-[80%] md:w-[88%] text-white text-left 2xl:ml-[22px] md:ml-[12px] 2xl:mt-[18px] md:pt-[8px] font-MontserratLight">
                    Makro Fashions aims to be the epitome<br></br> of excellence,
                    pioneering innovation and<br></br> setting new standards in the global<br></br>
                    fashion industry. .
                  </div>
                </div>
              </div>
              <div className="object-contain 2xl:mt-[-132px] 2xl:ml-[22px]  md:ml-[-12px] md:mt-[-74px]  ">
                <img src={india} alt="Foo img " className="2xl:h-[471px] 2xl:w-[402px] md:h-[364px] md:w-[302px] " />
              </div>
            </div>
          </div>

          <div className=" h-[50%] sm:h-full w-[100%] sm:w-[50%] gap-[20px] flex flex-col  ">
            <div className="w-full h-[100%] sm:h-[60%]  flex felx-row gap-[5px] sm:gap-[20px]">
              <div className="2xl:h-[528px] md:h-[431px] w-[50%] bg-[#171717] rounded-[30px] flex-col 2xl:mt-[-10px] md:mt-[-5px]">
                <div className=" mt-[-22px]">
                  <div className="w-full flex text-left text-[20px] md:text-[44px] 2xl:text-[61px] font-Cinzel text-white gradient-makro text-transparent 2xl:leading-[4.4rem]  2xl:mt-[52px] md:mt-[48px] ml-[22px]">
                    MAKRO
                  </div>
                  <div className="w-full flex  text-left text-[20px] md:text-[44px] 2xl:text-[61px] font-Cinzel text-white gradient-makro text-transparent 2xl:leading-[5.4rem]  2xl:mt-[-25px] md:mt-[-18px] ml-[22px]">
                    FASHIONS
                  </div>
                </div>
                <div className="w-full h-[60%] items-start px-[20px] py-[20px] justify-end flex flex-col text-white font-bold 2xl:mt-[4px] 2xl:ml-[4px] md:mt-[28px]">
                  <div className=" 2xl:text-[38px] md:text-[24px] font-Urbanist  2xl:leading-[2.4rem]  md:leading-[1.5rem]   2xl:mb--22px] md:mb-[12px]  ">
                    About <br></br>Makro{" "}
                  </div>
                  <div className=" 2xl:text-[12px] md:text-[10px] font-normal 2xl:w-[70%] md:w-[83%] items-center justify-center flex text-left 2xl:mb-[-41px] text-[#c5c5c5]  md:mb-[11px] font-AvenirRoman">
                    Leveraging a decade of industrial expertise from Essarpe,
                    Makro Fashions emerged in 2006 with a grander vision, poised
                    to redefine excellence in the fashion industry.
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-[50%] h-full md:mt-[15px] gap-[20px]">
                <div className="2xl:w-full 2xl:h-[41%] md:w-full md:h-[195px] bg-[#2875F3] felx  rounded-[30px] flex items-start justify-center flex-col px-[10px] py-[5px] sm:gap-[40px] 2xl:mt-[-98px] md:mt-[-155px]">
                  <div className="text-white 2xl:ml-[-52px]  w-[80%] md:text-[32px] 2xl:text-[38px] 2xl:ml-[-52px] md:ml-[-33px] md:pt-[5px] md:pr-[2px] font-black font-Urbanist text-left sm:text-center">
                    Mission
                  </div>
                  <div className="2xl:text-[12px] 2xl:w-[79%]  2xl:ml-[12px] 2xl:mt-[-12px] md:text-[11px] sm:text-[10px] text-left md:w-full text-white p-[10px] md:mt-[-25px] ">
                    Makro Fashions strives to redefinegarment manufacturing by
                    blending creativity,precision, and sustainability to exceed
                    customer expectations worldwide.
                  </div>
                </div>
                <div className="2xl:h-[50%] 2xl:w-full md:w-full md:h-[215px]  flex flex-col items-center justify-center bg-[#F5F5F5] rounded-[30px] px-[10px] py-[10px] 2xl:mt-[-10px]">
                  <div className="w-[80%] h-[40%] md:ml-[-33px]">
                    <img src={eec} alt="Foo img" className="2xl:w-[700px] 2xl:h-[90px] md:h-[75px] " />
                  </div>
                  <div className="md:text-[10px] 2xl:text-[12px] font-norma h-[60%] text-left w-[59%] md:w-[80%] md:ml-[51px] items-center justify-center flex mr-[74px] ">
                    Founded in 2000, Essarpe Exports, the parent company of Makro
                    Fashions, boasts a rich legacy of global export operations and
                    strategic partnerships with renowned fashion brands.
                  </div>
                </div>
              </div>
            </div>

            <div className="2xl:w-full 2xl:h-auto sm:h-[40%] items-center justify-center flex 2xl:mt-[-52px] md:mt-[-185px]">
              <img src={workers} alt="Foo img" className=" object-fill  2xl:h-[344px] md:h-[233px] " />
            </div>
          </div>
        </div>

        {/* FOURTH CERTIFICATION SECTION */}
        <div id="establishment" className="bg-white 2xl:h-[44px] md:h-[224px] md:mt-[-244px]  2xl:mt-[144px]">

        </div>
        {/* <div className="w-[80%] bg-[#B2B2B2] h-[1px] ml-[122px] 2xl:mt-[72px] md:mt-[-132px]  "></div> */}
        <div className="bg-[#fdfdfd] h-[1307px] mt-[-172px]">
          <div className=" h-screen sm:h-[900px] 2xl:mt-[172px] 2xl:w-[89%] sm:w-[80%] felx flex-row items-center justify-center  my-[100px] gap-[10px] 2xl:ml-[122px] md:ml-[92px] " >
            <div className=" w-full h-[50%] sm:h-[40%] flex gap-[30px] flex-col sm:flex-row items-center justify-center md:mt-[-44px]  2xl:pt-[312px]">
              <div className="2xl:w-[951px] 2xl:h-[395px] md:w-[1759px] md:h-[303px] sm:w-[60%] h-full flex items-center justify-center rounded-[74px] shadow-2xl bg-white">
                <img
                  src={disnep}
                  alt="Foo img"
                  className=" md:scale-4 rounded-[30px] 2xl:w-[18 44px] md:w-[889px]  "
                />
              </div>
              <div className="w-full  md:pl-[44px] sm:w-[40%] h-full font-Urbanist flex items-center sm:items-end justify-center flex-col rounded-[30px] 2xl:text-[38px] md:text-[68px] sm:text-[60px] 2xl:text-[93px] font-semibold 2xl:leading-[5.9rem] md:leading-[4.8rem] md:mr-[72px] 2xl:ml-[1px]  ">
                <div className="md:pl-[-44px]">our proud</div>
                <div className="gradient-certificate inline-block text-transparent ">
                  certifications
                </div>
              </div>
            </div>
            {/* <div className="w-[99%] h-full bg-red-200 flex gap-[55px] mx-auto mt-[50px]">
               <div className="flex flex-col w-[584px] h-[452px] bg-green-200 rounded-[83px]">

               </div>
               <div className="flex flex-col w-[1059px] h-[452px] bg-yellow-200">

               </div>
          </div> */}
            <div className="2xl:w-[1559px] md:w-[1219px]  2xl:h-[472px] sm:h-[40%] flex  pt-[30px] gap-[30px] flex-col sm:flex-row  2xl:mt-[212px] 2xl:ml-[34px] ">
              <div className="2xl:w-[887px] md:w-[542px] 2xl:h-[452px] sm:h-full flex items-end justify-center flex-col 2xl:rounded-[83px] md:rounded-[63px] bg-[#F5F5F5]   px-[2 0px] py-[20px] gap-[40px]">

                <div className=" w-full font-black h-[30%] text-left mr-[-32px] mt-[42px] 2xl:pl-[84px] md:pl-[44px]  ">

                  <img
                    src={year}
                    alt="year img"
                    className="2xl:w-[194px] md:w-[124px] 2xl:ml-[-59px] md:ml-[-39px] md:mt-[-19px] "

                  />
                </div>
                <div className="text-[15px] text-start  sm:w-[70%] text-[#655CCB] h-[70%] flex items-end justify-end flex-col rounded-[83px]  md:mt-[49px] md:mr-[-19px]">
                  <div className="w-full 2xl:text-[49px] md:text-[39px] sm:text-[30px] font-black font-Urbanist md:pt-[-29px] ">
                    Establishment
                  </div>
                  <div className="text-[#5F5F5F] 2xl:text-[15px] sm:text-[12px] md:mt-[-8px] md:mr-[-1px] mr-[2px] w-full font-MontserratLight pb-[38px]">
                    Founded in 2006, Makro prioritises quality and<br></br> ethical
                    practices, fostering growth for both the<br></br> company and its
                    collaborators.
                  </div>
                </div>
              </div>
              <div className="2xl:w-[1759px] md:w-[759px]  2xl:bg-red-200 2xl:h-[452px] sm:h-full flex items-center justify-center rounded-[83px] shadow-2xl bg-gradient-to-r from-[#7D51BA] to-[#2875F3] 2xl:mr-[2px] md:ml-[12px]">
                <div className="text-[10px] sm:text-[16px] 2xl:text-[34px] text-center w-full text-white  font-AvenirRoman font-normal pt-[113px] pb-[113px]">
                  Makro's commitment to quality shines through its<br></br> certifications.
                  Our Sedex certificate reflects<br></br> sustainable practices, while our
                  Authentic Disney<br></br> License signifies excellence. Partnerships like
                  these<br></br> affirm our unwavering dedication to quality standards.
                </div>
              </div>
            </div>
          </div>
        </div>






        {/* FIFTH FABRIC EVOLUTION SECTION */}
        {/* <div id="infrastructure" className="bg-white 2xl:h-[44px] md:h-[524px] md:mt-[-244px]  2xl:mt-[144px]" >

        </div> */}

        <div className="sm:h-screen h-[60vh] w-[100%] flex items-center justify-center flex-col 2xl:mt-[-272px] md:mt-[-572px]">
          <div className="w-full 2xl:h-[489px] bg-[#F8F8F8] flex flex-col sm:flex-row items-center justify-center  2xl:py-[149px] md:py-[149px]" id="infrastructure" >
            <div className="w-[50%]  h-[50%] sm:h-full flex items-center justify-center flex-col 2xl:mt-[-82px]">
              <div className="text-[#1C9B91] text-[17px] font-thin flex w-[70%] sm:w-[90%] 2xl:font-medium 2xl:mb-[12px] 2xl:ml-[180px] md:ml-[150px] 2xl:tracking-[0.13em] md:mr-[-19px] md:mb-[-19px] font-AdamBold">
                I N F R A S T R U C T U R E
              </div>
              <div className="text-[45px] sm:text-[70px] 2xl:text-[99px] flex text-[#3E3E3E] w-[80%] flex-col 2xl:leading-[90px]  sm:leading-[70px] sm:items-start items-center font-Urbanist mt-[22px] ml-[82px]">
                <div className="font-normal font-Adam">State of the Art </div>
                <div className="font-bold font-Adam">Facility.</div>
              </div>
            </div>
            <div className=" w-[50%]  h-[50%] sm:h-full flex items-center justify-center text-[12px] sm:text-[20px] text-[#666666] ">
              <div className="makro 2xl:text-[21px] md:text-[18px]  2xl:w-full md:w-full text-left 2xl:ml-[159px] 2xl:mt-[-82px] md:ml-[119px] leading-[2.1rem] font-normal font-AvenirRoman" >
                Makro's world-class Apparel Manufacturing facility<br></br> embodies
                state-of-the-art infrastructure and<br></br> sustainable practices. From
                yarn to finished apparel,<br></br> we ensure comprehensive coverage and
                collaboration<br></br> to deliver excellence at every stage of production
                and <br></br>shipment.
              </div>
            </div>
          </div>
          {/* <div className="w-[100%] sm:w-full h-[90%] md:h-[90%] ">
            <img
              src={iron}
              alt="Foo img"
              className="object-cover md:object-cover sm:w-full sm:h-full "
            />
          </div> */}


        </div >

        {/* slide SECTION */}
        <div className="relative w-screen h-[915px] z-10 slide  2xl:mt-[-322px] md:mt-[-152px]">
  <IronSlider />
 <button>
  <div className="absolute z-20 top-0 left-0 w-full h-full flex justify-center items-center  ">


 <div className="">
    <VimeoVideo/>
    <div className="2xl:ml-[457px] 2xl:mt-[-127px] md:ml-[457px] md:mt-[-127px]  text-[32px]">
  <img
    src={left}
    alt="Foo img"
    className="md:w-[37px] 2xl:w-[34px] arrow-animation"
  />
</div>
    <div className="2xl:ml-[-1344px] md:ml-[-944px] text-[24px] 2xl:mt-[-54px] md:mt-[-74px]   opacity-[0.1] relative">
        <a href="https://www.youtube.com/watch?v=zNAN8AzrBNY" target="_blank" className="font-Urbanist">Watch the Full video here</a>
        <div className="arrow"></div>
    </div>
</div>

  
  </div>
  </button> 
</div>

        {/* vime SECTION */}
       

        {/*SIXTH SCALE SECTION */}
        <div className="2xl:w-[90%] sm:w-[80%] h-screen sm:h-[700px] flex items-center sm:items-start justify-start sm:flex-row flex-col 2xl:mt-[249px] md:mt-[249px] 2xl:ml-[122px] md:ml-[142px]">
       
          <div className="w-full 2xl:w-[50%] md:w-[50%] sm:w-[50%] h-[50%] sm:h-full  flex-col flex items-center sm:items-start justify-center 2xl:mt-[-192px] md:mt-[-212px] 2xl:text-[97px] 2xl:ml-[-22px] md:ml-[-72px] ">
            <div className="text-[#3E3E3E] 2xl:text-[60px] md:text-[48px] sm:text-[55px] 2xl:mt-[1px] md:mt-[-12px] font-extralight leading-[2.7rem] font-Adam">
              the
            </div>
            <div className=" bg-gradient-to-b from-[#62B5B0] to-[#21706C] inline-block text-transparent bg-clip-text  h-auto 2xl:text-[97px] md:text-[90px] font-semibold  2xl:mt-[-15px] sm:-mt-[40px] leading-[7.7rem] font-Adam tracking-[-0.04em]">
              substantial
            </div>
            <div className="bg-gradient-to-b from-[#62B5B0] to-[#21706C] inline-block text-transparent bg-clip-text 2xl:text-[97px] md:text-[90px] tracking-[-0.04em ] sm:text-[100px] font-normal 2xl:-mt-[40px] md:-mt-[52px] leading-[7.1rem] font-Adam tracking-[-0.04em]">
              scale.
            </div>
            <div className=" 2xl:text-[22px] md:text-[18px] w-full font-MontserratLight text-[#666666] leading-[1.9rem] text-left mt-[72px] font-normal">
              Our facility boasts top-tier equipment, capable of<br></br> producing 80,000
              apparels monthly, with scalability<br></br> ensured by our extensive
              collaborators. Safety and<br></br> security equipment are integrated
              throughout,<br></br> ensuring a secure and efficient production<br></br> environment.
            </div>
          </div>
          <div className="w-[full sm:w-[50%] h-[50%] sm:h-full flex flex-row mt-[-94px] 2xl:ml-[-12px] md:ml-[152px] md:mt-[-189px]  " >
            <div className="h-full 2xl:w-[50%] md:w-[50%] flex flex-col items-center justify-center md:ml-[12px]">
              <div className=" w-full h-[30%] flex flex-col items-center justify-center sm:items-start pb-[42px] ml-[-19px]">
                <div className="flex flex-row items-end justify-start gap-[10px]">
                  <div>
                    <img
                      src={number1}
                      alt="Foo img"
                      className="md:w-[187px] 2xl:w-[264px] "
                    />
                  </div>
                  <div className="2xl:text-[21px] sm:text-[18px]  md:mt-[-11px] font-normal h-[40%] ">
                    sq ft.
                  </div>
                </div>
                <div className="2xl:text-[21px] md:text-[18px] mt-[12px]">
                  Factory area
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#CACACA] bg-opacity-45"></div>
              <div className=" w-full h-[40%] flex flex-col items-center justify-center sm:items-start pb-[42px] pt-[22px] md:pt-[59px]">
                <div className="flex flex-row items-end justify-start 2xl:-mt-[-30px]">
                  <img
                    src={number4}
                    alt="Foo img"
                    className="md:w-[199px] 2xl:w-[274px] ml-[-8px] "
                  />
                </div>
                <div className="2xl:text-[21px]  font-normal 2xl:-mt-[-10px] pb-[28px] md:mt-[9px] 2xl:ml-[-10px] ">
                  Factory Worker Capacity
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#CACACA] bg-opacity-45"></div>
              <div className=" w-full h-[30%] flex flex-col items-center justify-center sm:items-start pb-[42px]">
                <div className="flex flex-row items-end justify-start mt-[42px]">
                  <img
                    src={number3}
                    alt="Foo img"
                    className="md:w-[149px] 2xl:w-[194px] 2xl:mt-[84px]  "
                  />
                </div>
                <div className="2xl:text-[21px]  font-normal mt-[12px]">
                  Average garment Shipped
                </div>
              </div>
            </div>
            <div className="w-[1px] h-full bg-[#CACACA] bg-opacity-45"></div>
            <div className="h-full 2xl:w-[50%] md:w-[50%]  flex flex-col items-center justify-center">
              <div className=" w-[90%] h-[29.8%] flex flex-col items-center justify-center sm:items-start pb-[42px]">
                <div className="flex flex-row items-end justify-start ml-[59px] font-Metropolis">
                  <div>
                    <img
                      src={number2}
                      alt="Foo img"
                      className="md:w-[259px] md:mt-[4px]"
                    />
                  </div>
                </div>
                <div className="2xl:text-[21px] font-normal mt-[22px] mr-[92px] md:ml-[67px]  md:mt-[19px] w-[89%]">
                  Monthly Production Capacity
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#CACACA] bg-opacity-45"></div>
              <div className="  w-[70%] h-[40%] flex flex-col items-center justify-center sm:items-start pb-[27px]">
                <br></br>
                <div className="flex flex-row items-end justify-start ml-[22px]">
                  <img
                    src={number5}
                    alt="Foo img"
                    className="md:w-[59px] 2xl:ml-[-4px]  md:ml-[5px] md:mt-[-12px] 2xl:w-[84px] 2xl:mt-[32px]  "
                  />
                </div>
                <div className="2xl:text-[21px] font-normal 2xl:ml-[17px]  mt-[12px] md:ml-[27px] 2xl:mt-[14px]  md:mt-[2px]">
                  Production Lines
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#CACACA] bg-opacity-45"></div>
              <div className=" 2xl:w-[80%] 2xl:h-[30%] md:w-[100%] md:h-[30%] flex flex-col items-center justify-center sm:items-start pb-[42px] ml-[99px] pt-[42px]">
                <div className="flex flex-row items-end justify-start gap-[10px]">
                  <div>
                    <img
                      src={thousand}
                      alt="Foo img"
                      className="md:w-[727px] 2xl:w-[327px] 2xl:mt-[117px] md:mt-[17px] ml-[-12px]"
                    />
                  </div>
                  <div className="2xl:text-[21px]  ml-[-14px] 2xl:pt-[34px]  md:pt-[8px]   font-normal h-[40%] ">
                    Meters
                  </div>
                </div>
                <div className="2xl:text-[21px] 2xl:mt-[1px] 2xl:pt-[1px]">
                  Woven Garment Production
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*SEVENT MANAGEMENT SECTION */}
        <div className="w-full h-[1387px] bg-[#042826] items-center justify-center flex">
          <div className="w-[80%] h-full felx felx-row ">
            <div className="w-[90%] h-[50%] flex items-center justify-end ml-[92px]">
              <div className="bg-[#003E3A] 2xl:w-[1150px] md:w-[84%] 2xl:h-[541px] md:h-[414px] rounded-[57px] flex flex-row items-center justify-center 2xl:mt-[42px] md:mt-[-42px] 2xl:ml-[2px] md:mr-[52px]" >
                <div className="text-white text-[30xp] font-regular w-[75%] h-full flex items-center justify-center">
                  <div className="w-[81%] 2xl:text-[35px] ml-[12px] md:text-[25px] font-metropolis gradient-founder text-left font-semibold">
                    “Customer satisfaction isn't just a goal; it's the heartbeat of Makro's growth. Building lasting relationships is our foundation, ensuring every stitch reflects our commitment to<br></br> excellence.”
                  </div>
                </div>
                <div className="relative w-[35%] h-full flex flex-col items-startc justify-center bg-cover bg-center mr-[22px] ">
                  <div className="relative">
                    {/* <img
                      src={founder}
                      alt="founder"
                      className="rounded-[100px] 2xl:w-[100%] object-contain"
                    /> */}
                    <img
                      src={quotes}
                      alt="quotes"
                      className="absolute top-0 left-0 xl:w-[100%] object-contain mt-[-42px] ml-[-22px] 2xl:mt-[-67px] md:mt-[-11px]"
                      style={{ opacity: 1.2 }} // Adjust opacity as needed
                    />
                  </div>
                  <div className="text-white text-[35px] font-semibold ml-[-54px] relative 2xl:mt-[318px] md:mt-[308px] 2xl:pt-[12px] md:pt-[2px] ">
                    Pandian
                  </div>
                  <div className="text-white 2xl:text-[19px] 2xl:mt-[-7px]  md:text-[14px] font-extralight ml-[-54px] relative">
                    Founder, Makro Fashions
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[50%] flex flex-row items-center justify-center 2xl:mt-[1px] md:mt-[-242px] ">
              <div className="flex flex-col items-start justify-center w-[60%] h-full leading-[100px] text-left 2xl:mt-[-241px] md:mt-[-122px] 2xl:ml-[-42px] md:ml-[-72px]">
                <div className="text-[#1C9B91] text-[20px] font-thin tracking-[0.6rem] font-AvenirRoman">
                  THE MANAGEMENT
                </div>
                <div className="gradient-voice 2xl:leading-[7.4rem] md:leading-[3.9rem]">
                  <div className="text-[#1C9B91] 2xl:text-[142px] md:text-[72px]  font-semibold font-Adam">
                    Voice
                  </div>
                  <div className="text-[#1C9B91] 2xl:text-[142px] md:text-[72px] 2xl:font-thin md:font-thin font-Adam  ">
                    from the
                  </div>
                  <div className="text-[#1C9B91] 2xl:text-[142px] md:text-[72px] 2xl:font-thin md:font-thin font-Adam">table</div>
                </div>
              </div>
              <div className="bg-[#003E3A] 2xl:w-[977px] 2xl:h-[579px] md:w-[911px] md:h-[444px]  rounded-[76px] flex flex-row items-center justify-center">
                <div className="text-white text-[30xp] font-regular w-[60%] h-full flex items-center justify-center">
                  <div className="w-full 2xl:text-[35px] md:text-[25px] font-metropolis font-semibold gradient-ceo text-left ml-[59px]">
                    “As a Millennial, I believe in blending tech and sustainability to rise onthe leaderboard. Innovation with integrity propels us forward.”
                  </div>
                </div>
                <div className="w-[53%] h-full flex flex-col items-start justify-center ">
                  <div>
                    {/* <img
                      src={quotes}
                      alt="founder"
                      className="rounded-[100px] xl:w-[80%] object-contain"
                    ></img> */}
                    <img
                      src={ceo}
                      alt="founder"
                      className="2xl:w-[100%] object-contain 2xl:mt-[-79px] 2xl:ml-[-22px] md:mt-[-52px] md:w-[88%] "
                    ></img>
                  </div>
                  <div className="text-white text-[31px] font-semibold 2xl:ml-[89px] 2xl:mt-[14px] md:ml-[72px]">
                    Krishnaprasad
                  </div>
                  <div className="text-white 2xl:text-[19px] md:text-[14px] font-extralight 2xl:ml-[79px] md:ml-[112px] lg:mr-[-42px]">
                    VP Marketing, Makro Fashions
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*EIGHT REVOLUTION SECTION */}
        <div id="sustainability" className="bg-white h-[44px]">

        </div>

        <div className="w-full sm:h-[120vh] hidden sm:flex flex-row items-center justify-center mt-[100px]" >
          <div className="w-[90%] h-[90%] flex felx-row items-center justify-center" >
            <div className="w-[40%] h-[100%] flex flex-col items-start justify-start gap-[40px]">
              <div className="leading-[70px]">
                <div className="text-[#6B9619] text-[20px] font-bold text-left font-AvenirRoman">
                  S U S T A I N A B I L I T Y
                </div>
                <div className="text-[#6B9619] md:text-[80px] 2xl:text-[120px] font-semibold text-left pb-[20px] font-Adam">
                  green from
                </div>
                <div className="text-[#6B9619] 2xl:text-[120px] md:text-[80px] font-normal 2xl:mt-[5px] 2xl:ml-[15px] md:mt-[-21px] font-Adam tracking-[-0.53em">
                  the start.
                </div>
              </div>

              <div className="w-[80%] h-auto text-[22px]  font-normal text-black text-left 2xl:leading-[155%] 2xl:mt-[22px] ">
                Since inception, Makro has embraced the Nature Movement,
                consistently integrating sustainable practices. Our commitment
                extends beyond mere adherence, as we continually strive to enhance
                our environmental contributions, setting new standards for
                stewardship.
              </div>
              <div className="w-[90%] h-[7%]">
                <img
                  src={sedex}
                  alt="Foo img"
                  className="object-contain w-full h-full rounded-[60px]"
                />
              </div>
              <div className="2xl:w-[842px] 2xl:h-[520px]  ml-[0px] font-Urbanist 2xl:text-[48px] md:text-[38px] z-10 border-[#6A6A6A] border rounded-[104px]  bg-white font-normal text-[#606901] flex items-center justify-center text-center">
                <div className="md:w-[721px] md:h-[371px] zxl:mt-[11px] md:mt-[74px]">
                  Our Commitment to<br></br><span className="text-[#006F54]">Sustainability,</span> join hands in<br></br> saving <span>nature</span> and
                  fighting<br></br> climate change.
                </div>
              </div>
            </div>
            <div className="w-[60%] h-full -mt-[100px] ">
              <img
                src={windmill}
                alt="Foo img"
                className="object-contain w-[90%] h-[1100px] rounded-[60px]"
              />
            </div>
          </div>
        </div>

        {/*NINTH CLIENT SECTION  bg-gradient-to-b from-[rgba(32,141,195,0.66)] to-[#208dc300]*/}

        <div id="clients" className="bg-white 2xl:mt-[1px] md:mt-[421px]">

        </div>
        <div className="w-[100%] h-[1072px] flex flex-col items-center justify-center 2xl:mt-[210px] md:mt-[482px]  rounded-tr-[300px] gradient-client" >
          <div className="h-[40vh] sm:h-[40vh] w-[90%] sm:w-[80%]  text-[#64CCFF]  items-center justify-center flex flex-col leading-[90px] mb-[50px] 2xl:mt-[82px]">
            <div className="2xl:text-[16px] md:text-[14px] font-bold text-[#64CCFF] tracking-[0.13em]">
              {" "}
              C L I E N T <span className="ml-[22px]">P A N E L</span>
            </div>
            <div className="text-[60px] sm:text-[40px] xl:text-[100px] font-normal tracking-tight text-white font-Adam">
              {" "}
              the <span className="font-bold">brands</span> we
            </div>
            <div className="text-[60px] sm:text-[80px] xl:text-[100px] font-normal tracking-tight text-white font-Adam">
              {" "}
              shook hands with
            </div>
          </div>



          {/* <div className="h-[70vh] w-[80%]  hidden sm:flex flex-wrap flex-row items-center justify-center gap-[50px] mb-[50px]">
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px]">
              <img src={redtag} alt="Foo img" className="w-[250px] h-[100px] " />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px]">
              <img
                src={twentyfour}
                alt="Foo img"
                className=" w-[230px] h-[65px]"
              />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px]">
              <img
                src={skinnydip}
                alt="Foo img"
                className="w-[210px] h-[40px] "
              />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px]">
              <img src={debenhams} alt="Foo img" className=" " />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px] 2xl:mt-[-244px] md:mt-[0px]" >
              <img src={pimkle} alt="Foo img" className="w-[263px] h-[65px] " />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px] 2xl:mt-[-244px] md:mt-[0px]">
              <img src={boohoo} alt="Foo img" className=" " />
            </div>
            <div className="w-[300px] h-[150px] rounded-[30px] shadow-2xl items-center justify-center flex px-[20px] py-[20px] 2xl:mt-[-244px] md:mt-[0px]">
              <img src={kiabi} alt="Foo img" className=" w-[220px] h-[73px]" />
            </div>
          </div> */}

          <div className="h-[70vh] w-[80%]  hidden sm:flex flex-wrap flex-row items-center justify-center gap-[50px] mb-[50px]">
            <img src={clients} alt="Foo img" className="2xl:mt-[-122px] " />

          </div>
        </div>

        {/*TENTH CONTATC SECTION */}
        <div id="contact" className="bg-transparent h-[122px]" >

        </div>
        <ThemeProvider theme={theme} >
          <div className="w-[100%] h-screen overflow-hidden flex items-center justify-center flex-col sm:pt-[0px] pt-[50px] 2xl:mt-[171px] md:mt-[-21px]" >
            <div>
              <img
                src={classbanner}
                alt="email"
                className="2xl:w-[1681px] 2xl:h-[711px] lg:w-[1951px] lg:h-[871px] md:w-[1341px] md:h-[711px] text-white md:mt-[-14px]  mb-[-912px] 2xl:ml-[-812px]  md:ml-[-642px] absolute  "
              />
            </div>
            <div className="w-[95%] h-[900px] bg-[#202020] z-10 flex items-center justify-center flex-col gap-[30px]">
              {/* <div className="h-[10%] w-full  sm:w-[95%] 2xl:text-[32px] font-light text-white flex items-center sm:items-end justify-center sm:justify-end font-Cinzel mt-[52px]">
              MAKRO FASHIONS
            </div> */}
              <img
                src={fotterlogo}
                alt="ftl"
                className="2xl:ml-[1294px] md:ml-[794px] 2xl:mt-[42px] md:mt-[42px]"
              />


              <div className="h-[90%] w-[78%] 2xl:mt-[152px] md:mt-[42px] ">

                <div className="w-full h-[100%] flex flex-col sm:flex-row items-start justify-center" >
                  
                  <div className="w-[90%] sm:w-[50%] h-[50%] 2xl:mr-[95px] 2xl:mt-[-82px]  md:ml-[-105px] sm:h-full flex items-start justify-center sm:justify-start flex-col sm:gap-[40px] "  id="sectionId1">
                    <div className="text-white 2xl:text-[105px] md:text-[35px] font-semibold font-Urbanist">
                      Let’s Talk
                    </div>
                    <div className="text-white text-[12px] sm:text-[22px] font-normal  2xl:w-[80%] md:w-[90%] text-left 2xl:mt-[-42px] ">
                      Book your call with Makro Fashions today to explore Targets,
                      Possibilities, and Foster Meaningful Relationships.
                    </div>
                    <div className="hidden sm:flex flex-row w-full h-auto  gap-[5px] text-white items-center justify-start ">
                      <img
                        src={email}
                        alt="email"
                        className="w-auto h-auto text-white"
                      />
                      <div className="w-auto h-auto text-white 2xl:ml-[14px] font-AvenirRoman">
                      krishna@makrofashions.com
                      </div>
                    </div>
                    <div className="hidden sm:flex flex-row w-full h-auto  gap-[5px] text-white items-center justify-start ">
                      <img
                        src={phone}
                        alt="email"
                        className="w-auto h-auto text-white"
                      />
                      <div className="w-auto h-auto text-white 2xl:ml-[14px] font-AvenirRoman">
                        +91 90430 38361
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-[50%] h-[50%] 2xl:mt-[-32px]  sm:h-[90%] flex items-start justify-center flex-col 2xl:ml-[1px] md:mr-[-142px]">
                  <form ref={form} onSubmit={sendEmail} className="flex w-full h-[50%] sm:h-[80%] flex-col sm:gap-0 gap-[40px]">
      <div className="flex flex-col sm:flex-row gap-[40px] h-[80%] sm:h-[20%]">
        <TextField
         
          id="standard-basic"
          label="name"
          variant="standard"
          color="primary"
          name="to_name"
          className="w-[50%] text-white"
        />

        <TextField
         id="standard-basic"
         label="email"
         variant="standard"
         color="primary"
         name="to_email"
         className="w-[50%] text-white"
        />
      </div>
      <div className="w-full h-[20%] mt-[42px]">
        <TextField
        id="standard-basic"
        label="message"
        variant="standard"
        color="primary"
        name="to_message"
        className="w-[100%] text-white"
        />
      </div>
      <div className="2xl:ml-[594px] md:ml-[394px]">
        <Button type="submit" className="w-[180px] h-[80px] rounded-[30px] 2xl:ml-[1100px] md:ml-[800px]" sx={{ borderRadius: "50px" }}>
          <img src={arrow} alt="arrow" className="w-[41%]" />
        </Button>
      </div>
    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-full h-[600px] -mt-[250px] sm:-mt-[350px] z-0"
              style={{
                backgroundImage: `url(${map})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            ></div>
          </div>
        </ThemeProvider>

        {/*ELEVENTH FOOTER SECTION */}

        <div className="hidden sm:flex h-screen w-full overflow-y-auto bg-[#0F0F0F] items-center justify-center">
          <div className="w-[80%] h-full flex flex-col items-center justify-center">
            <div className=" h-[20%] w-[95%] text-[35px] font-light text-white flex items-end justify-end flex-row gap-[20px]">
              <div className="w-[70%] h-[10%] flex items-center justify-center">
                <div className="w-full h-[1px] bg-[#7D7D7D]"></div>
              </div>
              <div className="w-[30%] h-[10%] flex items-center justify-center font-Cinzel">
                <img src={fotterlogo} alt="arrow" />
              </div>
            </div>
            <div className="h-[80%] w-full items-center justify-center flex flex-col ">
              <div className="w-[60%] h-[80%] flex flex-row items-center justify-center ">
                <div className="w-[40%] h-full flex items-start justify-center text-white font-light flex-col gap-[20px] text-left text-[21px] font-AvenirRoman">
                  <div onClick={() => scrollToSection('home')} style={{ cursor: 'pointer' }} >Home</div>
                  <div onClick={() => scrollToSection('establishment')} style={{ cursor: 'pointer' }}>Establishment</div>
                  <div onClick={() => scrollToSection('infrastructure')} style={{ cursor: 'pointer' }}>Infrastructure</div>
                  <div onClick={() => scrollToSection('clients')} style={{ cursor: 'pointer' }}>Clients</div>
                  <div onClick={() => scrollToSection('sustainability')} style={{ cursor: 'pointer' }}>Sustainability</div>
                </div>
                <div className="h-[50%] w-[20%] ">
                  <div className="bg-[#949393] w-[1px] h-full"> </div>
                </div>
                <div className="w-[40%] h-full flex items-center justify-center text-white font-light flex-col gap-[20px] text-left font-AvenirRoman text-[23px]">
                  <div className="flex flex-col ">
                    <div className="flex">
                      <span><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-8" /> {/* Address icon */}
                      </span><div className="text-left">
                        No.49, Tekic Tea Nagar,<br></br> Mudalipalayam, A, Sidco,
                        <br></br> Tirupur, Tamil Nadu. - 641602 <br></br>
                        <br></br>
                        <br></br>
                        <div className="flex">
                          <span><FontAwesomeIcon icon={faPhoneAlt} className="ml-[-47px]" /> {/* Phone icon */}
                          </span>+91 90430 38361
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[20%] w-full flex items-center justify-center text-[#C8C8C8] text-opacity-60 text-[14px] font-light mt-[-22px]">
                © 2024 Makro Fashions, All Rights Reserved.
              </div>
            </div>
          </div>
        </div>




      </div>

      {/* MOBILE Device */}
      {/* <div className="fixed z-40 w-full h-auto">
          <MobileNavbar />

        </div> */}
      <div className="w-full mx-auto sm:block md:hidden 2xl:hidden overflow-hidden">
        <div className='fixed top-0 w-full z-10'>
          <div className='navbar'>
            <MobileNavbar />
          </div>
        </div>
        {/* First section*/}
        <div className="h-[627px] flex flex-col justify-end " style={bgbanner}>
          <div className="text-center font-Urbanist text-white text-[36px] font-semibold leading-[2.4rem]">Elegant Details, <br></br>Enduring Beauty. </div>
          <div className="font-Urbanist font-normal text-[18px] text-white text-center mt-[7px]">Discover the Makro Difference</div>
          <div className="text-center font-AvenirRoman text-[12px] mt-[12px] text-white pb-[63px]">At Makro Fashions, we redefine the art of garment manufacturing,<br></br>
            blending modern aesthetics with timeless elegance. Our<br></br> commitment to
            intricate patterns and unique imported fabrics sets<br></br> us apart in an
            industry driven by mediocrity.</div>
        </div>
        {/*second section*/}
        <div className="font-Urbanist font-semibold text-[30px] pt-[27px]">not just another</div>

        <div className="bg-[#CBFB45] font-Urbanist font-semibold text-[27px] pl-[27px] bg-opacity-45 rounded-[17px] w-[85%] flex mx-auto">
          garment manufacturer
        </div>

        <div className="font-Avenir text-[12px] text-[#474747bf] w-[89%] flex mx-auto mt-[28px]">Elevating standards, crafting excellence. Makro Fashions redefines garment manufacturing with unmatched precision and exotic flair. Experience the difference today.</div>
        <img src={mbpacking} className="w-[329px] h-[147px] flex mx-auto mt-[18px]" />
        <div className="flex flex-row mt-[9px] h-[188px] w-[79%] mx-auto gap-[10px]">
          <div className="flex flex-col w-[149px] h-[242px] bg-[#171717] rounded-[7px]">
            <div className=" ">
              <div className="w-full h-[70px] text-left flex text-[20px] font-Cinzel text-white text-transparent gradient-makro mt-[14px] ml-[13px]">
                MAKRO
              </div>
              <div className="w-full h-[70px] text-left flex text-[20px] font-Cinzel text-white text-transparent gradient-makro mt-[-44px] ml-[13px]">
                Fashions
              </div>
            </div>
            <div className="font-Urbanist text-[11px] text-white ml-[-52px] font-semibold mt-[32px]">About Makro</div>
            <div className="font-AvenirRoman text-[8px] w-[84%] text-[#ffffffbf] text-left ml-[12px] leading-[139.4%]">Leveraging a decade of industrial expertise from Essarpe, Makro Fashions emerged in 2006 with a grander vision, poised to redefine excellence in the fashion industry.</div>
          </div>
          <div className="flex flex-col w-[164px] h-[243px]  rounded-[7px] gap-[7px]">
            <div className="flex flex-col w-[174px] h-[131px] bg-[#2875F3]  rounded-[7px]">
              <div className="font-Urbanist text-[11px] text-white mt-[13px] ml-[11px] font-semibold text-left">Mission</div>
              <div className="text-[8px] font-AvenirRoman text-left w-[89%] text-white ml-[11px] pt-[21px]">Makro Fashions strives to redefine garment manufacturing by blending creativity, precision, and sustainability to exceed customer expectations worldwide.
              </div>
            </div>
            <div className="flex flex-col w-[174px] h-[154px] bg-[#F5F5F5] rounded-[7px]">
              <img
                src={founded}
                alt="ftl"
                className="w-[126px] h-[36px] mx-auto ml-[21px] mt-[5px]"
              />
              <div className="font-AvenirRoman text-[8px] text-[#171717] leading-[133%] text-left w-[89%] mt-[27px] ml-[11px]">Founded in 2000, Essarpe Exports, the parent company of Makro Fashions, boasts a rich legacy of global export operations and strategic partnerships with renowned fashion brands.</div>
            </div>
          </div>
        </div>

        <div className="flex flex-row h-[184px] w-[79%] mx-auto mt-[65px] gap-[7px]">
          <div className="flex flex-col w-[165px] h-[194px] gap-[7px]">
            <div className="w-[185px] h-[90px] bg-[#F5F5F5] rounded-[7px]">
              <div className="font-AvenirRoman text-[8px] text-[#171717] leading-[111.8%] text-lcenter w-[84%] mt-[17px] ml-[14px]">Located in Tiruppur's pulsating centre, Makro Fashions epitomises style, innovation, and quality, thriving amidst the global apparel hub's vibrant energy.</div>
            </div>
            <div className="w-[185px] h-[155px] bg-[#961919] rounded-[7px]">
              <div className="font-Urbanist text-[16.62px] font-bold leading-[86.6%] text-white text-left mt-[29px] ml-[14px]">Vision</div>
              <div className="font-AvenirRoman font-normal text-[8px] text-[#ffffff] leading-[133%] text-left w-[79%] mt-[12px] ml-[14px]">Makro Fashions aims to be the epitome of excellence, pioneering innovation and setting new standards in the global fashion industry.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[147px] h-[197px] ml-[22px]">
            <img
              src={indiabg}
              alt="ftl"
              className="h-[197px] w-full"
            />
          </div>
        </div>

        <div className="w-[75%] mx-auto mt-[21px]">
          <img
            src={wokersmb}
            alt="ftl"
            className=""
          />
        </div>
        {/* <div className="w-[281px] h-[1px] bg-[#B0B0B0] mt-[42px] mx-auto"></div> */}
        {/*second section end*/}

        {/*Third section start*/}
        <div className="bg-[#fdfdfd] h-[534px] mt-[-22px]">
          <div className="md:block 2xl:hidden">
            <div className="">
              <div className="flex flex-row h-[296px] w-[87%] mx-auto mt-[58px] pt-[42px] gap-[2px] ml-[14px]"  >
                <div className="w-[204px] h-[266px] ">
                  <div className="flex flex-col w-[226px] h-[157px]">
                    <div className="flex flex-col w-[183px] h-[55%] leading-[197%] ml-[12px]">
                      <div className="flex flex-col text-left text-[28px] font-Urbanist font-semibold text-black ">our proud</div>
                      <div className="flex flex-col text-left text-[28px] font-Urbanist font-semibold gradient-certificate ">
                        certifications
                      </div>
                    </div>
                    <div className="mt-[14px] ml-[5px]">
                      <div className="w-[178px] h-[185px] rounded-[19px] bg-[#F5F5F5] ">
                        <img
                          src={year}
                          alt="year img"
                          className="w-[42%] pl-[15px] pt-[24px]"
                        />
                        <div className="mt-[42px]">
                          <div className="font-Urbanist text-[12px] text-[#655CCB] ml-[15px]">Establishment</div>
                          <div className="text-[#5F5F5F] text-[8px] w-[65%] ml-[58px] text-left">
                            Founded in 2006, Makro prioritises quality and ethical
                            practices, fostering growth for both the company and its
                            collaborators.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="establishment">
                  <div className="w-[230px] h-[266px]">
                    <img
                      src={certicatemb}
                      alt="ftl"
                      className=" ml-[-37px] mt-[-34px] w-[340px] h-[324px] object-cover "

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[361px] h-[148px] bg-red-200 flex mx-auto mt-[29px] rounded-[19px]  bg-gradient-to-r from-[#7D51BA] to-[#2875F3] ">
            <div className="text-[12px] text-center w-[78%] pt-[7px] flex mx-auto text-white mt-[17px] fontAvenirRoman ">
              Makro's commitment to quality shines through its certifications.
              Our Sedex certificate reflects sustainable practices, while our
              Authentic Disney License signifies excellence. Partnerships like
              these affirm our unwavering dedication to quality standards.
            </div>
          </div>
        </div>
        {/* <img src={certify} /> */}

        {/*Third section end*/}




        {/*Fourth section start*/}
        <div id="infrastructure" className="bg-white h-[44px] ">

        </div>
        <div className="w-[100%] mx-auto mt-[0px] bg-[#F8F8F8]" >
          <div className="text-[#1C9B91] tracking-[0.53em] text-[10px] font-thin font-Avenir text-center ml-[22px] pt-[22px]" >
            INFRASTRUCTURE
          </div>
          <div className="text-[38px] flex text-[#3E3E3E] tracking-[-0.04em] flex-col leading-[2.7rem] text-center font-Adam mt-[22px] ml-[21px]">
            <div className="font-normal">State Of the Art</div>
            <div className="font-bold">Facility.</div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col w-[347px] h-[144px] mt-[22px] mx-auto">
              <div className="makro text-[12px] text-center ml-[12px]  font-AvenirRoman text-[#666666] mt-[-12px]">
                Makro's world-class Apparel Manufacturing facility embodies
                state-of-the-art infrastructure and sustainable practices. From
                yarn to finished apparel, we ensure comprehensive coverage and
                collaboration  to deliver excellence at every stage of production
                and shipment.
              </div>
            </div>
            <div className="flex flex-col w-[311px] h-[144px]  mt-[-72px] mx-auto ml-[122px]">
             <a href="https://www.youtube.com/watch?v=zNAN8AzrBNY" target="_blank"> <div className="flex flex-row mx-auto mt-[25px]">

                <img
                  src={playiconmb}
                  alt="Foo img"
                  className="ml-[22px]"
                /> <span className="flex flex-row text-[8px] font-AvenirRoman w-[94%] mt-[24px] ml-[-8px]">play the premises tour</span>
              </div></a>
            </div>
          </div>

        </div>
        {/* <img
          src={iron}
          alt="Foo img"
          className="w-[430px] h-[230px] mt-[-25px]"
        /> */}

        <div className=" w-[100%] h-[230px] md:block 2xl:hidden mt-[-29px]">
          <IronSlider />
        </div>
        {/*Fourth section end*/}

        {/*Fivth section start*/}
        <div className="leading-[2.5rem] mt-[34px]">
          <div className="text-[#3E3E3E] text-[21px] text-center font-extralight font-Adam ">
            the
          </div>
          <div className=" bg-gradient-to-b from-[#62B5B0] to-[#21706C] mt-[-7px] text-transparent bg-clip-text text-[45px] font-semibold font-Adam">
            substantial
          </div>
          <div className="bg-gradient-to-b from-[#62B5B0] text-[45px] to-[#21706C] mt-[-5px] font-thin text-transparent bg-clip-text font-Adam">scale</div>

          <div className="text-[12px] w-[78%] mx-auto font-AvenirRoman text-[#666666] leading-[164%] text-center font-normal mt-[12px]">
            Our facility boasts top-tier equipment, capable of producing 80,000
            apparels monthly, with scalability ensured by our extensive
            collaborators. Safety and security equipment are integrated
            throughout,ensuring a secure and efficient production environment.
          </div>
        </div>

        <div className="numbers mt-[32px] ml-[12px]">
          <div className="flex flex-row w-[74%] mx-auto">
            <div className="flex flex-col w-[151px] h-[89.07px] border-b border-[#eAeAeA] border-r ">
              <div className="flex flex-row pb-[4px]">
                <div className="flex flex-col">
                  <img
                    src={mb1}
                    alt="Foo img"
                    className="flex"
                  />
                </div>
                <div className="flex flex-col text-[9px] ml-[4px] pt-[-12px] justify-end font-Metropolis">
                  Sq ft.
                </div>
              </div>
              <div className="text-[9px] font-Metropolis text-left mt-[8px]">Factory area</div>
            </div>
            <div className="flex flex-col w-[151px] h-[89.07px]  border-b border-[#eAeAeA]">
              <img
                src={mb2}
                alt="Foo img"
                className="w-[109px] ml-[32px]"
              />
              <div className="text-[9px] font-Metropolis text-left mt-[11px] ml-[32px] w-[89%] ">Monthly Production Capacity</div>

            </div>
          </div>
          <div className="flex flex-row w-[74%] mx-auto">
            <div className="flex flex-col w-[151px] h-[111.07px] border-b border-[#eAeAeA] border-r">
              <img
                src={mb3}
                alt="Foo img"
                className="w-[111px] ml-[-4px] mt-[25px]"
              />
              <div className="text-[9px] font-Metropolis text-left mt-[12px] w-[89%] ">Factory Worker Capacity</div>
            </div>
            <div className="flex flex-col w-[151px] h-[111.07px]  border-b border-[#eAeAeA]">
              <img
                src={mb5}
                alt="Foo img"
                className="w-[35px] ml-[32px] mt-[25px]"
              />
              <div className="text-[9px] font-Metropolis text-left mt-[10px] ml-[32px] w-[89%] ">Production Lines</div>
            </div>
          </div>
          <div className="flex flex-row w-[74%] mx-auto">
            <div className="flex flex-col w-[151px] h-[111.07px] pt-[14px] border-[#eAeAeA] border-r">
              <img
                src={mb4}
                alt="Foo img"
                className="w-[88px] ml-[2px] mt-[18px]"
              />
              <div className="text-[9px] font-Metropolis text-left mt-[12px]  w-[89%] ">Average garment Shipped</div>
            </div>
            <div className="flex flex-col w-[151px] h-[81.07px] ">
              {/* <img
                src={thousand}
                alt="Foo img"
                className="w-[128px] ml-[32px] mt-[18px]"
              />
              <span className="flex flex-col">Meters</span> */}

              <div className="flex flex-row h-[122px]">
                {/* <div className="flex flex-col-500 h-[72px] w-[422px]">
                  <img
                    src={thousand}
                    alt="Foo img"
                    className="w-[194px] ml-[32px] mt-[18px]"
                  />
                </div>
                <div className="flex flex-col h-[122px] w-[50%]">Meters</div> */}
                <img
                  src={thousand}
                  className="w-[84%] ml-[31px] mt-[44px]"
                /><span className="text-[9px] font-Metropolis mt-[62px] ml-[4px]">Meters</span>

              </div>
              <div className="text-[9px] font-Metropolis flex flex-col text-left mt-[16px] ml-[32px]  w-[89%] ">Woven Garment Production</div>

            </div>
          </div>
        </div>

        {/*sixth section start*/}

        <div className="mt-[22px] bg-[#042826] h-[654px]">
          <div className="text-[10px] text-[#1C9B91] Uppercase pt-[49px] tracking-[0.53em]">THE MANAGEMENT</div>
          <div className="font-Adam text-[46px] gradient-voice font-bold leading-[1.7rem] mt-[17px]">
            voice
          </div>
          <div className="font-Adam text-[46px] gradient-voice leading-[2.4rem]">
            from the
          </div>
          <div className="font-Adam text-[46px] gradient-voice leading-[1.7rem]">
            table
          </div>

          <div className="w-[333px] h-[191px] rounded-[17px] ml-[22px] mt-[29px]" style={founder}>
            <div className="font-Metropolis text-[13px] font-semibold w-[81%] text-left text-white ml-[21px] pt-[17px] gradient-testimonials">“Customer satisfaction isn't just a goal; it's the heartbeat of Makro's growth. Building lasting relationships is our foundation, ensuring every stitch reflects our commitment to excellence.”</div>
            <div className="text-[9px] font-semibold font-Metropolis text-white text-right mr-[47px] mt-[48px]">Pandian</div>
            <div className="text-[7px] font-light font-Metropolis text-white text-right mr-[11px] mt-[1px]">Founder, Makro Fashions</div>
          </div>

          <div className="w-[333px] h-[191px] rounded-[17px] ml-[52px] mt-[14px]" style={vp} >
            <div className="font-Metropolis text-[13px] font-semibold w-[63%] text-left text-white ml-[21px] pt-[17px] gradient-testimonials">“As a Millennial, I believe in blending tech and sustainability to rise on the leaderboard. Innovation with integrity propels us forward.”</div>
            <div className="text-[9px] font-semibold font-Metropolis text-white text-right mr-[23px] mt-[42px]">Krishnaprasad</div>
            <div className="text-[7px] font-light font-Metropolis text-white text-right mr-[17px] mt-[1px]">VP Marketing, Makro Fashions</div>
          </div>
        </div>

        {/*seventh section start*/}
        <div className="w-full h-[944px] ">
          <div className="text-[10px] font-AvenirRoman tracking-[0.53em] text-[#67A800] text-center uppercase	pt-[74px]" id="sustainability">Sustainability</div>
          <div className="font-Adam text-[48px] text-[#6B9619] tracking-[-0.04em] font-bold mt-[-4px]">green from</div>
          <div className="font-Adam text-[48px] text-[#6B9619] tracking-[-0.04em] leading-[0.8rem]">the start.</div>
          <div className="font-AvenirRoman text-[12px] text-[#1C1C1C]  text-center w-[98%] mt-[29px] mx-auto leading-[164%]">Since inception, Makro has embraced the Nature Movement,<br></br> consistently integrating sustainable practices. Our commitment<br></br> extends beyond mere adherence, as we continually strive to<br></br> enhance our environmental contributions, setting new <br></br>standards for stewardship.</div>
          {/* <div>
        <div style={{ position: 'relative' }}>
  <img
    src={windmb}
    alt="Foo img"
    className="mx-auto mt-[15px]"
    style={{ position: 'absolute', top: 0, left:55 }}
  />

  <div className="bg-commit w-[225px] h-[107px] pt-[5px] mx-auto text-[#606901]" style={{ position: 'relative', zIndex: 1,top: 370 }}>
    Our Commitment to<br></br><span className="text-[#006F54]">Sustainability,</span> join hands in<br></br> saving <span className="underline">nature</span> and fighting<br></br> climate change.
  </div>
</div>
</div>

<img
    src={certicatemb}
    alt="Foo img"
    className="mx-auto mt-[75px]"
    
  /> */}

          <div className="">
            <img
              src={windmb}
              alt="Foo img"
              className="mx-auto mt-[15px] relative"
            />

            <div className=" w-[245px] h-[117px] pt-[5px] mx-auto text-[#606901]  absolute mt-[-62px] ml-[89px] font-Urbanist text-[14px]" style={{ background: '#FFFFFF', border: '0.231965px solid rgba(106, 106, 106, 0.42)', borderRadius: '24.1243px' }}>
              <div className="pt-[12px]">
                Our Commitment to<br></br><span className="text-[#006F54]">Sustainability,</span>joint hands in<br></br> saving nature and fighting<br></br> climate change.
              </div>
            </div>
            <img src={sedex} className="mt-[72px] w-[52%]  ml-[108px]" />
          </div>


        </div>

        {/*eidth section start*/}

        <div className="h-[594px] w-[422px]" id="clients" style={{ background: 'linear-gradient(180.17deg, #208DC3 -13.18%, rgba(32, 141, 195, 0) 95.81%)', borderRadius: '0px 109px 0px 0px' }}>

          <div className="text-[#64CCFF] pt-[52px] tracking-[0.53em]">CLIENT PANEL</div>
          <div className="text-[40px]  font-normal tracking-tight text-white font-Adam mt-[27px]">
            {" "}
            the <span className="font-bold">brands</span> we
          </div>
          <div className="text-[40px]  font-normal tracking-tight text-white font-Adam leading-[1.7rem]">
            {" "}
            shook hands with
          </div>

          <div id="clients">
            <img src={clientsmb} className="mx-auto mt-[22px] ml-[42px]" />
          </div>



        </div>

        {/*ninth section start*/}
        <div className="ml-[12px] mt-[42px] " >
          <div className="w-[379px] h-[584px] bg-[#d5d5d5] z-5 absolute overflow-hidden" >
            <div className="" id="contact">
              <div className="bg-[#212121] h-[570px] w-[395px] mx-auto realtive mt-[15px] ml-[-4px] z-10" >
                <div className="font-Urbanist font-semibold text-[38px] text-white pt-[27px] pl-[27px] text-left" id="sectionId">Let’s Talk</div>
                <div className="font-AvenirRoman text-white text-[12px] leading-[17px] text-left w-[71%] pl-[27px] pt-[-12px]"> Book your call with Makro Fashions today to explore Targets,
                      Possibilities, and Foster Meaningful Relationships.</div>
                      <form ref={form} onSubmit={sendEmail}>
                <div className="flex flex-col sm:flex-row gap-[40px] h-[9%] sm:h-[20%] pl-[27px] mt-[44px]">
                  <TextField
                    id="standard-basic"
                    label="name"
                    variant="standard"
                    color="primary"
                    name="to_name"
                    className="w-[70%] text-white"
                    InputProps={{
                      style: {
                        paddingBottom: '0px', // Adjust the padding bottom as needed
                        borderBottom: '0.5px solid white' // Adjust the border height and style as needed
                      }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '13px', color: '#B7B7B7', position: 'absolute' } // Adjust label styles
                    }}
                  />


                </div>
                <div className="flex flex-col sm:flex-row gap-[40px] h-[9%] sm:h-[20%] pl-[27px] mt-[12px]">
                  <TextField
                    id="standard-basic"
                    label="email"
                    variant="standard"
                    color="primary"
                    name="to_email"
                    className="w-[70%] text-white"
                    InputProps={{
                      style: { paddingBottom: '5px' } // Adjust padding as needed
                    }}
                    InputLabelProps={{
                      style: { fontSize: '13px', color: '#B7B7B7', position: 'absolute' } // Adjust label styles
                    }}
                  />


                </div>
                <div className="w-full h-[20%] mt-[42px] pr-[-92px] ml-[-12px]">
                  <TextField
                    id="standard-basic"
                    label="message"
                    variant="standard"
                    color="primary"
                    className="w-[80%] text-white"
                    name="to_message"
                    InputProps={{
                      style: { paddingBottom: '0px' } // Adjust padding as needed
                    }}
                    InputLabelProps={{
                      style: { fontSize: '13px', color: '#B7B7B7', position: 'absolute' } // Adjust label styles
                    }}
                  />
                </div>

                <div className="w-full h-[20%] flex items-end justify-end mt-[52px]">
                  <Button

                    className="w-[50px] h-[20px] rounded-[30px]"
                    sx={{ borderRadius: "50px" }}
                    type="submit"
                  >
                    <img src={arrow} alt="arrow" className="mr-[82px] mb-[-14px]" />
                  </Button>
                </div>
                </form>

                <div className="flex flex-row mt-[14px]">
                  <div className="flex flex-col   h-[144px] w-full">
                    <img
                      src={phone}
                      alt="email"
                      className="w-[15%] h-[15%] ml-[17px]"
                    />
                  </div>
                  <div className="flex flex-col h-[144px] w-full text-left ml-[-254px]">
                    <div className="w-auto h-auto text-white 2xl:ml-[14px] font-AvenirRoman">
                      +91 90430 38361
                    </div>
                  </div>

                </div>

                <div className="flex flex-row mt-[-102px]">
                  <div className="flex flex-col   h-[144px] w-full">
                    <img
                      src={email}
                      alt="email"
                      className="w-[15%] h-[15%] ml-[17px]"
                    />
                  </div>
                  <div className="flex flex-col h-[144px] w-full text-left ml-[-254px]">
                    <div className="w-auto h-auto text-white 2xl:ml-[14px] font-AvenirRoman">
                    krishna@makrofashions.com
                    </div>
                  </div>

                </div>

                <div>

                </div>

              </div>


            </div>
          </div>
          {/*ELEVENTH FOOTER SECTION  MOBILE */}

        </div>

        <img
          src={mapmb}
          alt="map"
          className="realtive mt-[590px] z-5"
        />


        {/* <div className="w-full h-[490px] bg-red-200"></div> */}

        <div className="w-full h-[265px] bg-black">
        <div className="w-[30%] h-[10%] ml-[254px] pt-[22px] font-Cinzel">
                <img src={fotterlogo} alt="arrow" />
              </div>
          <div className="flex flex-row mt-[-24px]">
           
            <div className="flex flex-col w-[70%] mx-auto h-[144px] mr-[22px]">
    <div className="flex">
     
    <div className="text-left text-white mt-[73px]">
    <div className="address-container">
        <span className="mt-[25px]">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-8" />
        </span>
        <div className="address-text mt-[22px] text-[11px]">
            No.49, Tekic Tea Nagar,<br />
            Mudalipalayam, A, Sidco,<br />
            Tirupur, Tamil Nadu. - 641602
        </div>
    </div>
    <br /><br /><br />
    <div className="flex mt-[-42px] text-[11px]">
        <span>
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-4" />
        </span>
        <span className="ml-[17px]">+91 90430 38361</span>
    </div>
</div>
    </div>
</div>
          </div>
        </div>

        <div className="h-[20%] w-full flex items-center justify-center text-[#C8C8C8] text-opacity-60 text-[14px] font-light mt-[-22px]">
                © 2024 Makro Fashions, All Rights Reserved.
              </div>
           <div className="w-full h-[41px] bg-black"></div>
      </div>

    </div>
  );
};

export default Premises;
