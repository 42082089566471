import "./App.css";
import Showcase from './Pages/Showcase.tsx'
import Landing from './Pages/Landing.tsx'
import Premisis from './Pages/Premisis.tsx'

import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Product1 from "./Pages/Product1.jsx";
import Product2 from "./Pages/Product2.jsx";
import Login from "./Pages/LogIn.jsx";
import Products from "./Pages/SliderContainer.jsx";
import Product3 from "./Pages/Product3.jsx";
import Product4 from "./Pages/Product4.jsx";
import Product5 from "./Pages/Product5.jsx";
import Product6 from "./Pages/Product6.jsx";
import Product7 from "./Pages/Product7.jsx";
import Product8 from "./Pages/Product8.jsx";
import Product9 from "./Pages/Product9.jsx";
import Product10 from "./Pages/Product10.jsx";
import Product11 from "./Pages/Product11.jsx";
import Product12 from "./Pages/Product12.jsx";
import Product13 from "./Pages/Product13.jsx";
import Product14 from "./Pages/Product14.jsx";
import Product15 from "./Pages/Product15.jsx";
import Product16 from "./Pages/Product16.jsx";
import Product17 from "./Pages/Product17.jsx";
import ImageSlider from "./Pages/TestSlider.jsx";
import Slider from "./Pages/TestSlider.jsx";
import ScrollSlider from "./Pages/TestSlider.jsx";
import LandingTest from "./Pages/TestSlider.jsx";
import MobileLanding from "./Pages/MobileLanding.jsx";
import ShowcaseMobile from "./Pages/ShowcaseMobile.jsx";
import FabricSlider from "./Pages/MobileFabric.jsx";
import ShowcaseHamburg from "./Pages/SHowcaseHamburg.jsx";
import MobileLogin from "./Pages/MobileLogin.jsx";
import Premisesmb from "./Pages/Premisesmb.jsx";
import RequestForm from "./Pages/RequestForm.jsx";
import RequestFormmb from "./Pages/RequestFormmb.jsx";
import ForgotForm from "./Pages/ForgotForm.jsx";
import ForgotFormmb from "./Pages/ForgotFormmb.jsx";
import ThankYou from "./Pages/ThankyouPage.jsx";
import ThankYouMb from "./Pages/ThankyouPageMb.jsx";
import Iphone from "./Pages/apple.jsx";

function App() {
  return (
    <div className="App">


      <Routes>
        <Route path="/" element={<LandingTest />} />
        <Route path="/Premises" element={<Premisis />} />
        <Route path="/Showcase" element={<Showcase />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/product1" element={<Product1 />} />
        <Route path="/product2" element={<Product2 />} />
        <Route path="/product3" element={<Product3 />} />
        <Route path="/product4" element={<Product4 />} />
        <Route path="/product5" element={<Product5 />} />
        <Route path="/product6" element={<Product6 />} />
        <Route path="/product7" element={<Product7 />} />
        <Route path="/product8" element={<Product8 />} />
        <Route path="/product9" element={<Product9 />} />
        <Route path="/Product10" element={<Product10 />} />
        <Route path="/product11" element={<Product11 />} />
        <Route path="/product12" element={<Product12 />} />
        <Route path="/product13" element={<Product13 />} />
        <Route path="/product14" element={<Product14 />} />
        <Route path="/product15" element={<Product15 />} />
        <Route path="/product16" element={<Product16 />} />
        <Route path="/product17" element={<Product17 />} />


        <Route path="/" component={Products} />
        <Route path="/landing1" element={<MobileLanding />} />
        <Route path="/Showcasemb" element={<ShowcaseMobile />} />
        <Route path="/Showcasembl" element={<Iphone/>} />
        <Route path="/Loginmb" element={<MobileLogin />} />
        <Route path="/Requestform" element={<RequestForm/>} />
        <Route path="/Requestformmb" element={<RequestFormmb/>} />
        <Route path="/Forgotpassword" element={<ForgotForm/>} />
        <Route path="/Forgotpasswordmb" element={<ForgotFormmb/>} />
        <Route path="/thankyou" element={<ThankYou/>} />
        <Route path="/thankyoumb" element={<ThankYouMb/>} />
      </Routes>
      <div className="ml-[122px] hidden">
        <FabricSlider />
      </div>
     
   
    </div>
  );
}

export default App;

