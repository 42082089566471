import React, { useState } from 'react';
import image1 from '../resources/product1.png';
import image2 from '../resources/product2.png';
import image3 from '../resources/product3.png';
import image4 from '../resources/product4.png';
import image5 from '../resources/product5.png';
import image6 from '../resources/productsix.png';
import image7 from '../resources/product7.png';
import image8 from '../resources/product8.png';
import image9 from '../resources/product9.png';
import image10 from '../resources/product10.png';
import image11 from '../resources/product11.png';
import image12 from '../resources/product12.png';
import image13 from '../resources/product13.png';
import image14 from '../resources/product14.png';
import image15 from '../resources/product15.png';
import image16 from '../resources/product16.png';
import image17 from '../resources/product17.png';
import image18 from '../resources/product18.png';

import cheronarrowleft from '../resources/chevron-left.svg';
import cheronarrowright from '../resources/chevron-right.svg';

const productImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18
];

function Slider({ images, currentSlide, onIndicatorClick }) {
  return (
    <div className='2xl:w-full md:w-[1122px] 2xl:ml-[1px] md:ml-[32px]'>
    <div className="carousel-content flex flex-wrap mx-auto 2xl:rounded-[87px] md:rounded-[87px]    2xl:pt-[44px] 2xl:pb-[44px] 2xl:mt-[42px] relative overflow-x-auto slide-transition">
      {images.map((image, index) => (
        <div key={index} className="w-1/3 p-4 image-container">
          <img
            src={image}
            alt={`Image ${index + 1}`}
            className="object-cover 2xl:ml-[42px] md:ml-[1px] w-[84%]" // Adjust width and height as needed
          />
        </div>
      ))}
      <div className="slider-indicators absolute bottom-4 left-1/2 transform -translate-x-1/2 flex">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className={`slider-indicator w-7 h-2 mx-1 rounded-[25px] 2xl:mt-[-22px] md:mb-[-49px] ${currentSlide === index ? 'bg-[#1FA95F]' : 'bg-gray-500'
              } cursor-pointer`}
            onClick={() => onIndicatorClick(index)}
          ></div>
        ))}
      </div>
    </div>
    </div>
  );
}

function Products() {
  const [currentSlider, setCurrentSlider] = useState(0);

  const sliders = [
    productImages.slice(0, 6), // First six images
    productImages.slice(6, 12), // Next six images
    productImages.slice(12) // Last six images
  ];

  const nextSlider = () => {
    setCurrentSlider((currentSlider + 1) % sliders.length);
  };

  const prevSlider = () => {
    setCurrentSlider((currentSlider - 1 + sliders.length) % sliders.length);
  };

  const handleIndicatorClick = (index) => {
    setCurrentSlider(index);
  };

  return (
    <div className="scrollable-container z-[20] relative ">
      <div className="carousel-container relative overflow-hidden w-[89%] mx-auto z-[40] ">
        <Slider
          images={sliders[currentSlider]}
          currentSlide={currentSlider}
          onIndicatorClick={handleIndicatorClick}
        />
        <button className="carousel-btn-left absolute top-1/2 left-0 transform -translate-y-1/2  text-white p-2  cursor-pointer text-white md:ml-[-8px] 3xl:ml-[-8px] " onClick={prevSlider}>
          <img src={cheronarrowleft} alt="Previous" />
        </button>
        <button className="carousel-btn-right absolute top-1/2 right-0 transform -translate-y-1/2 text-white p-2  cursor-pointer text-white md:mr-[48px] 3xl:mr-[-8px]" onClick={nextSlider}>
          <img src={cheronarrowright} alt="Next" />
        </button>
      </div>
    </div>
  );
}

export default Products;
