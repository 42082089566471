import React from 'react';
import thankyoubgmb1 from "../resources/tkbgmb.png";
import messageicon from "../resources/Icon.png";
import { Link } from 'react-router-dom';
import MobileNavbarShowcase from './MobileNavbarShowcase';
const ThankYouMb = () => {
    const thankyoubgmb= {
        backgroundImage: `url(${thankyoubgmb1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };
  return (
    <div className='w-[430px] h-screen overflow-x-hidden' style={thankyoubgmb}>
         <div className='fixed w-[430px]'>
                <div className='navbar'>
                    <MobileNavbarShowcase />
                </div>
            </div>
      <div className='flex item-center justify-center  pt-[300px]'>
        <img src={messageicon} className='w-[111px] h-[107px]' />
        </div>
        <div className='font-Metropolis text-[19px] text-[#a7a7a7] font-thin leading-[3.4rem] mt-[24px]'>We have received your request, </div>
        <div className='font-Metropolis text-[19px] text-[#a7a7a7] font-thin leading-[1.4rem]'>You will <span className='font-Metropolis text-[19px] text-white font-bold '>receive your <br></br>credentials shortly.</span></div>
        <div className='font-Metropolis font-light text-[19px] text-white mt-[34px]'>
  Till then, Keep Exploring Existing <br></br><span className='font-Metropolis font-light text-[19px] text-[#E3A90E]'><Link to="/Showcasemb">Show<span style={{ borderBottom: '2px solid #E3A90E' }}>case</span></Link></span>
</div>
    </div>
  );
};

export default ThankYouMb;