import React, { useEffect } from 'react';

const VimeoVideo = () => {
  const videoId = '934958537';

  useEffect(() => {
    const apiUrl = 'https://player.vimeo.com/api/player.js';

    const initializePlayer = () => {
      const options = {
        id: videoId,
        autoplay: true,
        loop: true,
        controls: false // Hiding controls via API option
      };

      // Load Vimeo player
      const player = new window.Vimeo.Player('vimeo-video-container', options);

      // Handle errors
      player.on('error', (error) => {
        console.error('Vimeo Player Error:', error);
      });
    }; 

    // Load Vimeo Player API script
    const loadScript = (url, callback) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript(apiUrl, initializePlayer);

    // Cleanup function
    return () => {
      // Remove the Vimeo player script when the component unmounts
      const script = document.querySelector(`script[src="${apiUrl}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div 
  id="vimeo-video-container" 
  className='2xl:mr-[1312px] md:mr-[912px] mt-[822px] rounded-[54px] overflow-hidden '
  style={{
    position: 'relative',
    boxShadow: '0 0 20px rgba(33, 112, 108, 0.5)', // Light green glow effect (#21706C)
    borderRadius: '54px'
  }}
></div>
  );
};

export default VimeoVideo;