import React from 'react';
import MobileNavbarShowcase from './MobileNavbarShowcase';
import bannershowcase from "../resources/bannershowcase.png";
import fabricmb from "../resources/fabricbannermb.png";
import apprealmb from "../resources/apprealmb.png";
import footermb from "../resources/footermb.svg"
import FabricSlider from './MobileFabric';
import FabricSlider1 from './MobileAppreal'
import { useNavigate } from 'react-router-dom';

const ShowcaseUpdated = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Premises#sectionId1')
    };
    const fabric = {
        backgroundImage: `url(${fabricmb})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const appreal = {
        backgroundImage: `url(${apprealmb})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };



    return (
        <div className='bg-black h-[2012px] overflow-x-hidden'>
            <div className='fixed w-[422px]'>
                <div className='navbar'>
                    <MobileNavbarShowcase />
                </div>
            </div>
            <div className='pt-[144px] items-center justify-center flex mx-auto'>
                <img src={bannershowcase} alt="banner" />

            </div>
            <div className='txt'>
                <div className='text-[30px] font-Cinzel text-white mt-[-32px]'>Welcome to Makro</div>
                <div className='text-white text-[11px] font-avenirbook '>
                    Explore Makro's Exotic Showcase featuring premium<br></br> tailored pieces crafted in large volumes for clients<br></br> worldwide.
                </div>
            </div>
            {/* <div className='flex flex-row mt-[52px] w-[97%] mx-auto gap-[5px]'>
                <div className='flex flex-col w-[28%] h-[224px] bg-[#0b0b0b] rounded-[15px]'>
                    <div className="text-white text-[25px] font-Metropolis font-light tracking-[-0.04em] mr-[45px] pt-[72px]">our<span className="border border-b-1 border-t-0 border-l-0 border-r-0 mt-[-11px] text-[#050505] text-transparent absolute w-[67px] ml-[-5px]">n</span></div>
                    <div className=' text-[25px] ml-[14px] font-Metropolis font-medium gradient-fabric leading-[102%] text-left tracking-[-0.05em]'>fabric<br></br> gallery.</div>
                </div>
                <div className='w-[283.12px] h-[192px] rounded-[15px]' style={fabric}>
      <div className='w-full h-auto text-[9px] font-AvenirRoman tracking-[139%] mx-auto text-white pt-[54px]'>
        Makro specialises in sourcing and crafting unique, intricate fabrics globally, with expertise in natural materials like Bamboo. Currently, we're innovating with Kapok and Banana fibers, exploring their potential for superior quality textiles.
      </div>
    </div>


            </div> */}
            
<div className="fabricsec mt-[44px] flex mx-auto items-center justify-center">
  <div className="flex gap-1 ml-[2px] mr-[2px]">
    <div className="w-[99px] h-[192px] bg-[#0B0B0B] rounded-[15px] flex flex-col justify-center items-center">
      <div className="text-white text-[22px] font-Metropolis font-light tracking-[-0.04em] relative">
        our
        <span className="border border-b-1 border-t-0 border-l-0 border-r-0 absolute top-[-11px] text-[#050505] t   ext-transparent w-[64px] ml-[-5px]">n</span>
      </div>
      <div className="text-[22px] font-Metropolis font-medium gradient-fabric leading-[102%] text-left tracking-[-0.05em] mt-1">
        fabric<br />gallery.
      </div>
    </div>
    <div className="w-[283.12px] h-[192px] rounded-[15px] flex items-center justify-center" style={fabric}>
      <div className="w-full h-full text-[9px] font-AvenirRoman  text-center p-4 text-white flex items-center justify-center">
        <div className="pt-[14px]">
          Makro specialises in sourcing and crafting unique, intricate fabrics globally, with expertise in natural materials like Bamboo. Currently, we're innovating with Kapok and Banana fibers, exploring their potential for superior quality textiles.
        </div>
      </div>
    </div>
  </div>
</div>
<div className="ml-[15px] xs:w-[1874px] mac:w-[1714px] rounded-[85px] pt-[22px]">
  <FabricSlider />
</div>
              {/* <div className='flex flex-row mt-[52px] pt-[19px] w-[97%] mx-auto gap-[5px]'>
                    <div className='flex flex-col w-[28%] h-[224px] gradient-apprealmb rounded-[15px]'>
                        <div className="text-white text-[25px] font-Metropolis font-light tracking-[-0.04em] mr-[45px] pt-[72px]">the<span className="border border-b-1 border-t-0 border-l-0 border-r-0 mt-[-11px] text-[#050505] text-transparent absolute w-[67px] ml-[-5px]">n</span></div>
                        <div className=' text-[25px] ml-[14px] font-Metropolis font-medium text-white leading-[102%] text-left tracking-[-0.05em]'>apparel<br></br> gallery.</div>
                    </div>
                    <div className='w-[283.12px] h-[192px] rounded-[15px]'style={appreal}>
                    <div className='w-full h-auto text-[9px] font-AvenirRoman tracking-[139%] mx-auto pt-[54px] text-[#505050]'>Dive into the intricate detailing of our curated styles. Experience premium quality work in our apparel gallery.<br></br><span className='text-[#CF980A]'>For access to our confidential catalogue, kindly request permission from Makro Admin.</span></div>
                </div>


                </div> */}

            <div className='h-[627px] bg-white w-[97%] mx-auto rounded-[32px] mt-[44px]'>
              
            <div className="apprealsec pt-[14px] flex mx-auto items-center justify-center pl-[8px]">
  <div className="flex gap-1 ml-[2px] mr-[2px]">
    <div className="w-[99px] h-[192px] gradient-apprealmb rounded-[15px] flex flex-col justify-center items-center">
      <div className="text-white text-[22px] font-Metropolis font-light tracking-[-0.04em] relative">
        the
        <span className="border border-b-1 border-t-0 border-l-0 border-r-0 absolute top-[-13px] text-[#050505] text-transparent w-[61px] ml-[-5px]">n</span>
      </div>
      <div className="text-[22px] font-Metropolis font-medium text-white leading-[102%] text-left tracking-[-0.05em] mt-1">
        apparel<br />gallery.
      </div>
    </div>
    <div className="w-[283.12px] h-[192px] rounded-[15px] flex items-center justify-center" style={appreal}>
      <div className="w-full h-auto text-[9px] font-AvenirRoman  text-center p-4 text-[#505050]">
        Dive into the intricate detailing of our curated styles. Experience premium quality work in our apparel gallery.<br />
        <span className="text-[#CF980A]">For access to our confidential catalogue, kindly request permission from Makro Admin.</span>
      </div>
    </div>
  </div>
</div>

                <div className="overflow-hidden">
                <div className='ml-[15px] xs:w-[1874px] mac:w-[1714px] rounded-[85px] pt-[22px]'>
                        <FabricSlider1 />
                    </div>

                </div>


            </div>

            <div className="w-full h-[265px] bg-black">
                <div className="flex flex-row">
                    <div className="flex flex-col w-[40%] h-[144px] ">
                        <div className="text-left text-[12px] text-white ml-[29px] mt-[93px] leading-[2.5em] border-2 pr-[22px] pl-[22px] pt-[12px] pb-[12px] rounded-[22px] border-[#717171]  ">
                            <button onClick={handleClick}>Get a Quote</button>

                        </div>
                    </div>
                    <div className="flex flex-col w-[60%] h-[144px] mr-[22px]">
                        <img
                            src={footermb}
                            alt=""
                            className="mt-[24px] w-[71%] ml-[42px] "
                        />
                        <div className="text-left text-[12px] leading-[19px] mt-[47px] ml-[42px] text-white font-AvenirRoman">
                        No.49, Tekic Tea Nagar,<br />
            Mudalipalayam, A, Sidco,<br />
            Tirupur, Tamil Nadu. - 641602
                        </div>
                        <div className="text-left text-white text-[12px] ml-[42px] mt-[22px]">
                            +91 9894147611
                        </div>
                    </div>
                </div>
            </div>





        </div>
    );
};

export default ShowcaseUpdated;