import React, { useState } from "react";
import Section1Image from "../resources/Imagebg.png";
import Section2Image1 from "../resources/Imagebg2new.png";
import Section2Image2 from "../resources/Imagebg1new.png";
import venture from "../resources/essarp.png";
import landinglogo from "../resources/landinglogo.png";
import makelogo from "../resources/makrologo.svg";
import { Link } from "react-router-dom";
import MobileLanding from "./MobileLanding";

const LandingTest = () => {
  const [isShowcaseHovered, setIsShowcaseHovered] = useState(false);
  const [isPremisesHovered, setIsPremisesHovered] = useState(false);

  return (
    <div>
      <div className="w-screen h-screen 2xl:block md:block hidden sm:hidden">
        <div className="h-[20vh] w-full flex items-center justify-center flex-col mt-[-22px]">
          <div>
            <img src={landinglogo} className="2xl:w-[81%] md: mx-auto mt-[-22px]" />
          </div>
          <div className="text-[#969696] text-[24px] font-normal font-AvenirRoman mt-[-8px] ">
            Experience the <span className="text-black">Makro</span>
          </div>
        </div>
        <div className="flex flex-row w-[82%] mx-auto h-[842px] mt-[-32px] ml-[224px]">
          <Link to="/Premises" className='flex items-center '>  <div
            className="2xl:w-[755px] 2xl:h-[665px] md:w-[555px] md:h-[568px]  relative overflow-hidden container hover03"
            onMouseEnter={() => setIsPremisesHovered(true)}
            onMouseLeave={() => setIsPremisesHovered(false)}
            style={{ position: 'relative' }}
          >

            <figure style={{ position: 'relative' }}>
              <div className="w-full h-[21px] ">
              </div>
              <img src={Section1Image} className="ml-[-1px] mt-[-32px]   " />
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', transition: 'background-color 0.3s', opacity: isPremisesHovered ? 1 : 0, zIndex: 1 }}></div>

              <div
                className={`text-black text-[62px] tracking-[-0.05em] absolute top-1/2 left-1/2 transform -translate-x-1/2 ${isPremisesHovered ? '-translate-y-3/4' : '-translate-y-1/2'} font-Metropolis font-medium`}
                style={{ transition: 'transform 0.8s', color: isPremisesHovered ? 'white' : 'black', zIndex: 2 }}
              >
                Prem<span style={{ borderBottom: isPremisesHovered ? '4px solid white' : '4px solid black' }}>ises</span>
              </div>
              <div className="text-center w-[89%] mx-auto font-Metropolis text-[14px]" style={{ color: 'white', display: isPremisesHovered ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, calc(-50% + 80px))', zIndex: 2 }}>
              Explore our establishment, cutting-edge infrastructure, certifications, and visionary approach to garment manufacturing. Click to witness excellence firsthand.
              </div>

            </figure>
          </div>
          </Link>
          <Link to="/Showcase" className='flex items-center'>  <div
            className="2xl:w-[755px] 2xl:h-[665px] md:w-[555px] md:h-[528px] md:mt-[-32px] 2xl:mt-[0px]  relative overflow-hidden container bg-[#f6f6f6]"
            onMouseEnter={() => setIsShowcaseHovered(true)}
            onMouseLeave={() => setIsShowcaseHovered(false)}
            style={{ position: 'relative' }}
          >
            <div class="container">
              <img
                src={Section2Image2}
                class="inset-0 2xl:w-[483px] md:w-[444px] 2xl:ml-[272px] md:ml-[118px] mt-[87px] 2xl:h-[614px] md:h-full img1"
                alt="Section 2 Image"
              />
            </div>
            <img
              src={Section2Image1}
              className="absolute inset-0 2xl:w-[493px] 2xl:ml-[72px] md:ml-[11px] mt-[47px] 2xl:h-full md:h-full  img2"

              alt="Section 2 Image"
            />
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'black', transition: 'background-color 0.3s', opacity: isShowcaseHovered ? 0.8 : 0, zIndex: 1 }}></div>

            <div
              className={`text-black text-[62px] tracking-[-0.05em] absolute top-1/2 left-1/2 transform -translate-x-1/2 ${isShowcaseHovered ? '-translate-y-3/4' : '-translate-y-1/2'} font-Metropolis font-medium`}
              style={{ transition: 'transform 0.8s', color: isShowcaseHovered ? 'white' : 'black', zIndex: 2 }}
            >
              Show<span style={{ borderBottom: isShowcaseHovered ? '4px solid white' : '4px solid black' }}>case</span>
            </div>
            <div className="text-center w-[89%] mx-auto font-Metropolis text-[14px]" style={{ color: 'white', display: isShowcaseHovered ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, calc(-50% + 80px))', zIndex: 2 }}>
            Experience our curated fashion pieces, intricate styles, and unique fabrics. Click to Unveil Makro's Masterpieces.
            </div>

          </div></Link>
        </div>
        <div className="2xl:h-[20vh] 2xl:w-full md:h-[10vh] md:w-full flex items-center justify-end mt-[-124px] ml-[-202px] ">
          <div className="pt-[10px] pr-[40px]">
            <img
              src={venture}
              alt={"Venture"}
              className="2xl:w-full 2xl:h-[100px] md:w-full md:h-[90px]"
            ></img>
          </div>
        </div>
      </div>

      <div className="sm:block md:hidden 2xl:hidden">
        <MobileLanding />
      </div>
    </div>
  );
};

export default LandingTest;
