import React from 'react';
import forgetbg2 from "../resources/Forgotbgmb.png";
import MobileNavbar from './MobileNavbar';
import MobileNavbarShowcase from './MobileNavbarShowcase';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// Functional component structure
const ForgotFormmb = () => {

    const [isUserRegistered, setIsUserRegistered] = useState(true); // Initially assuming user is registered

    const forgotbgmb = {
        backgroundImage: `url(${forgetbg2})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        // Here you can check if the user is registered or not, based on your application logic
        // For now, let's assume the user is not registered
        setIsUserRegistered(false);
    };
    return (
        <div className='w-[425px] h-screen bg-black'>
            <div>
                <MobileNavbarShowcase/>
            </div>
            <div className='pt-[144px]'>
        <div className='w-[379px] h-[403px] rounded-[22px] mx-auto pt-[44px]' style={forgotbgmb}>
         <div className='text-center  font-Metropolils text-[23px] font-thin text-white leading-[1.7rem] pt-[43px]'>Enter your <span className='text-center font-Metropolils text-[23px] font-normal text-white'>Registered Email </span><br /> to Change Password. </div>
         <form onSubmit={handleSubmit} className='mt-[39px]' >
         <input
       type="text"
         placeholder="Email"
         name='email'
         required
        className="h-[44px] w-[319px]  rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white"
        />
        <div className='mt-[11px] pt-[31px] pb-[22px] '>
        <button type="submit" className="w-[123px] h-[44px] px-4 py-2 font-Metropolis font-medium text-[18px] text-white rounded-md gradient-button  ">Next</button>
        </div>
        </form>
        {!isUserRegistered && <p className="text-white text-center font-Metropolis ">This user is not registered with us yet. Kindly <Link to="/Requestformmb"><span className='underline'>request access.</span></Link></p>}

        </div>
        </div>
        </div>
        // <div className='w-[425px] h-screen bg-black sm:block 2xl:hidden md:hidden'>
        //     <div className='' style={forgotbgmb}>
        //         <div className='text-center font-Metropolils text-[13px] font-thin text-white leading-[1.4rem] pt-[24px]'>Enter your <span className='text-center font-Metropolils text-[13px] font-normal text-white'>Registered Email </span><br /> to Change Password. </div>
        //         <form>
        //             <input
        //                 type="text"
        //                 placeholder="Email"
        //                 name='email'
        //                 required
        //                 className="h-[44px] w-[199px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white"
        //             />
        //               <div className='mt-[31px] pt-[31px] pb-[22px] '>
        //                     <button type="submit" className="w-[123px] h-[44px] px-4 py-2 font-Metropolis font-medium text-[18px] text-white rounded-md gradient-button  ">Next</button>
        //                 </div>
        //         </form>
        //     </div>
        // </div>
    );
}

export default ForgotFormmb;