import React from 'react';
import loginlogo from "../resources/loginlogo.png";
import AccessForm from './AccessFrom';
import formbg from "../resources/requestform.png";

const RequestForm = () => {
  const formbanner = {
    backgroundImage: `url(${formbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  // Apply this style to prevent y-axis scrolling
  const pageStyle = {
    overflowY: 'hidden',
  };

  return (
    <div className='bg-black 2xl:w-[1920] 2xl:h-[1080] overflow-hidden md:h-[907px] mb-[0px]' style={pageStyle}>
      <div className='2xl:pt-[58px] md:pt-[33px] '>
        <div className='flex flex-row gradient-form 2xl:w-[92%] 2xl:h-[609px] 3xl:w-[92%] 3xl:h-[409px] 3xl:mt-[-17px]  md:w-[92%] md:h-[494px] mx-auto rounded-[45px] 2xl:mr-[75px] realtive z-[5] '>
          <div className='flex flex-col w-[20%] h-[144px] '>
            <img
              src={loginlogo}
              alt="logo"
              className='w-[144px] h-[74px] 2xl:ml-[58px] 2xl:mt-[58px] md:ml-[58px] md:mt-[58px] '
            />
          </div>
          <div className='flex flex-col w-[80%] h-[144px]'>
            <div className='font-Metropolis 2xl:text-[58px] md:text-[38px] font-medium text-white text-left 2xl:mt-[58px] 2xl:ml-[352px] 3xl:ml-[152px] md:mt-[58px] 3xl:mt-[17px] md:ml-[212px] '>Explore Makro's Masterpieces</div>
            <div className='text-white text-left 2xl:ml-[422px] md:ml-[222px] 3xl:ml-[252px]   font-AvenirRoman 2xl:text-[17px] md:text-[18px] '>Immerse Yourself in Makro's Design Legacy: Request Access Today
            </div>
          </div>
        </div>
      </div>
      <div className=' 2xl:w-[1273px] 2xl:h-[779px] 3xl:w-[1273px] 3xl:h-[599px] md:w-[954px] md:h-[605px]  rounded-[57px] mx-auto z-[10] 2xl:mt-[-407px] 2xl:ml-[444px] md:mt-[-327px] 3xl:mt-[-257px]  md:ml-[244px] 3xl:ml-[254px]   ' style={formbanner}>
        <div className='flex flex-row'>
          <div className='flex flex-col w-[40%] h-[544px] 2xl:ml-[133px] 2xl:mt-[305px] 3xl:mt-[215px] md:ml-[133px] md:mt-[215px]'>
            <div className='text-white 2xl:text-[55px] md:text-[35px] text-left font-thin font-AvenirRoman'>Fill the Form to</div>
            <div className='text-white 2xl:text-[55px] md:text-[35px] text-left font-medium leading-[2.4rem] font-AvenirRoman'> Get Access</div>
          </div>
          <div className='flex flex-col w-[60%] 2xl:h-[524px] 3xl:h-[404px]  '>
            <div className='mt-[42px]'>
              <AccessForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
