import React from 'react';
import makelogo from "../resources/makrologo.svg";
import { Link } from 'react-router-dom';
import { useState } from 'react';
const Navbar2 = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isTextHovered, setIsTextHovered] = useState(false); // Declare isTextHovered state

  return (
    <div>
      <div className='nav ml-[0px] md:block lg:block 2xl:hidden ' >
        <div className='md:w-[1441px] '>
          <div className='flex flex-row'>
            <div className='flex flex-col w-[30%] h-[144px] pt-[59px] ml-[22px]'>
              <div className='left-menus flex justify-around 2xl:ml-[133px] '>
                <div
                  className='font-AvenirRoman text-[#1e1e1e] font-normal  '
                  onClick={() => scrollToSection('home')}
                >
                  Home
                </div>
                <div
                  className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer'
                  onClick={() => scrollToSection('establishment')}
                >
                  Establishment
                </div>

                <div
                  className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer'
                  onClick={() => scrollToSection('infrastructure')}
                >
                  Infrastructure
                </div>
              </div>
            </div>
            <div className='flex flex-col w-[40%] h-[144px] relative'>
              <div
                className='font-Cinzel uppercase 2xl:text-[35px] md:text-[25px] 2xl:mt-[44px] md:mt-[39px] 2xl:mt-[1px] md:ml-[44px] 2xl:ml-[114px] hover:-translate-y-[10px] transition duration-1000'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Link to="/" className='flex items-center '>
                  <img
                    src={makelogo}
                    alt="logo"
                  />
                </Link>

              </div>
              {(isHovered || isTextHovered) && (
                <div
                  className='absolute ml-[142px] mt-[91px] flex items-center  '

                >
                  <Link to="/" className='flex items-center '>
                    <span>Redirect back to Home</span>
                    {/* <div className='ml-[12px]'><img src={arrow} alt="arrow" /></div> */}
                  </Link>
                </div>
              )}
            </div>
            <div className='flex flex-col w-[30%] h-[144px] mr-[22px] relative'>
              <div className='right-menus flex justify-around 2xl:mr-[133px] md:mr-[17px] md:mt-[57px]
            '>
                <div
                  className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer ml-[-122px] 2xl:mr-[93px] 2xl:mt-[1px] md:mt-[7px]'
                  onClick={() => scrollToSection('sustainability')}
                >
                  Sustainability
                </div>
                <div
                  className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer 2xl:mt-[1px] md:mt-[7px] 2xl:ml-[1px] md:ml-[57px]'
                  onClick={() => scrollToSection('clients')}
                >
                  Clients
                </div>
                <div className='w-[1px] h-[44px] bg-[#869593] 2xl:ml-[43px]'></div>
                <button className='rounded-[40px] bg-[#0000004D] px-[47px] py-[4px] text-white font-AvenirRoman 2xl:text-[20px] font-normal ml-[-24px]' onClick={() => scrollToSection('contact')}>Contact</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Navbar2;