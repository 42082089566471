import React, { useState,useRef } from 'react';

import emailjs from '@emailjs/browser';
import {  useNavigate } from 'react-router-dom'; // Import useHistory hook

function AccessFormmb() {
  const navigate = useNavigate(); // Initialize useHistory hook
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ib9gtgl', 'template_9i8eicj', form.current, {
        publicKey: 'I4lBD175Tz08-E5cr',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setShowSuccessMessage(true);
          form.current.reset();
          // Navigate to another component upon successful form submission
          navigate('/thankyoumb'); // Specify the path of the component you want to navigate to
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div className="mx-auto ">
      <form ref={form} onSubmit={sendEmail} className="space-y-4" >
        <div>
          <input
            type="text"
            placeholder="Username"
            name='from_name'
            required
            className="w-[319px] h-[44px]  rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[12px] text-[#797979] text-white"
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Email"
            name="from_email"
            required
       
            className="w-[319px] h-[44px]  rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[12px] text-[#797979] text-white"
          />
        </div>
        <div className="flex space-x-4  ml-[37px]">
          <label className='flex items-center bg-[#282828] h-[44px] w-[93px] rounded-[10px]'>
            <input
              type="radio"
              name="to_selector"
              value="Buyer"
            
              className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
            />
            <span className="ml-2 font-Metropolis font-medium text-[14px] text-[#797979]">Buyer</span>
          </label>
          <label className='flex items-center bg-[#282828] h-[44px] w-[93px] rounded-[10px]'>
            <input
              type="radio"
              name="to_selector"
              value="Seller"
            
              className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
            />
            <span className="ml-2 font-Metropolis font-medium text-[14px] text-[#797979]">Seller</span>
          </label>
          <label className='flex items-center bg-[#282828] h-[44px] w-[93px] rounded-[10px]'>
            <input
              type="radio"
              name="to_selector"
              value="Other"
              
              className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
            />
            <span className="ml-2 font-Metropolis font-medium text-[14px] text-[#797979]">Other</span>
          </label>
        </div>
        <div>
          <input
            type="text"
            placeholder="Company Name"
            name="to_companyname"
            required
          
            className="w-[319px] h-[44px]  rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[12px] text-[#797979] text-white"
          />
        </div>
        <div>
          <input
            type="tel"
            placeholder="Phone Number"
            name="to_phonenumber"
            required
       
            className="w-[319px] h-[44px]  rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[12px] text-[#797979] text-white"
          />
        </div>
        <div>
          <textarea
            placeholder="Comments"
          
            name="to_comments"
            required
            rows="4" 
            className="w-[319px] h-[181px]  rounded-[10px] bg-[#282828] pt-[22px] pl-[21px] font-Metropolis font-medium text-[12px] text-[#797979] text-white"
          ></textarea>
        </div>
        <div className=''>
          <button type="submit" className=" px-4 py-2 font-Metropolis font-medium text-[17px] text-white rounded-md gradient-button mt-[-44px]">Request Access</button>
        </div>
      </form>
      {showSuccessMessage && <p className='text-white'>We have received your request, You will receive your credintials shortly.</p>}

    </div>
  );
}

export default AccessFormmb;