import React, { useState, useEffect } from 'react';
import iron1 from "../resources/iron1.png";
import iron2 from "../resources/iron2.png";
import iron3 from "../resources/iron3.png";
import iron4 from "../resources/iron4.png";
import iron5 from "../resources/iron5.png";
const IronSlider = () => {
    const [images] = useState([iron1, iron2, iron3, iron4, iron5]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3900); // Change image every second

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="w-full h-full relative overflow-hidden">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`absolute inset-0 transition-opacity duration-400 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
                        }`}
                >
                    <img src={image} alt={`Image ${index + 1}`} className="w-full h-full object-cover" />
                </div>
            ))}
        </div>
    );
};

export default IronSlider;
