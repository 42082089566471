import React from 'react';
import makelogo from "../resources/makrologo.svg";

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

const MacBookNavBar = () => {
  return (
    <div>
        <div className='nav ml-[0px] mackbook:block 2xl:hidden md:hidden '>
      <div className='mackbook:w-[1214px] bg-red-200'>
        <div className='flex flex-row'>
          {/* <div className='flex flex-col w-[30%] h-[144px] 2xl:ml-[154px]'>
            <div className='left-menus flex justify-around 2xl:ml-[133px] md:ml-[17px] md:mt-[77px] 2xl:mt-[57px] 2xl:mb-[57px] md:mr-[-18px] '>
              <div
                className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer 2xl:ml-[-93px] md:ml-[-13px]  '
                onClick={() => scrollToSection('home')} style={{ marginLeft: '-170px' }}
              >
                Home
              </div>
              <div
                className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer'
                onClick={() => scrollToSection('establishment')} style={{ marginLeft: '20px' }}
              >
                Establishment
              </div>
              
              <div
                className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer'
                onClick={() => scrollToSection('infrastructure')} style={{ marginLeft: '20px' }}
              >
                Infrastructure
              </div>
            </div>
          </div> */}
          <div className='flex flex-col w-[40%] h-[144px]'>
            <div className='font-Cinzel uppercase 2xl:text-[35px] md:text-[25px] 2xl:mt-[44px] md:mt-[69px] 2xl:mt-[1px] md:ml-[-54px] 2xl:ml-[114px] '>
            <img
              src={makelogo}
              alt="logo"
            />
            </div>
          </div>
          <div className='flex flex-col w-[30%] h-[144px]'>
            <div className='right-menus flex justify-around 2xl:mr-[133px] md:mr-[17px] md:mt-[67px] 2xl:mt-[57px] 2xl:mb-[57px]'>
              <div
                className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer ml-[-122px] 2xl:mr-[93px] 2xl:mt-[1px] md:mt-[7px]'
                onClick={() => scrollToSection('sustainability')}
              >
                Sustainability
              </div>
              <div
                className='font-AvenirRoman text-[#1e1e1e] font-normal 2xl:text-[20px] md:text-[14px] cursor-pointer 2xl:mt-[1px] md:mt-[7px] 2xl:ml-[1px] md:ml-[57px]'
                onClick={() => scrollToSection('clients')}
              >
                Clients
              </div>
              <div className='w-[1px] h-[44px] bg-[#869593] 2xl:ml-[43px] '></div>
              <button className='rounded-[40px] bg-[#0000004D] px-[47px] py-[4px] text-white font-AvenirRoman 2xl:text-[20px] font-normal 2xl:ml-[44px] md:ml-[112px]' onClick={() => scrollToSection('contact')}>Contact</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default MacBookNavBar;