import AccessFormmb from "./AccessFrommb";
import MobileNavbar from "./MobileNavbar";
import MobileNavbarShowcase from "./MobileNavbarShowcase";
import formbgmb from "../resources/formbannermb.png";

const RequestFormmb = ({ name }) => {
  const formbanner = {
    backgroundImage: `url(${formbgmb})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div className="bg-black h-[944px] overflow-x-hidden">
      <div className="">
        <MobileNavbarShowcase />
      </div>

      <div className="gradient-form w-[405px] h-[417px] mx-auto rounded-[37px] pt-[39px] mt-[29px] z-[5]">
        <div className="font-Metropolis text-[25px] font-medium text-center text-white">Explore Makro's Masterpieces</div>
        <div className="font-Metropolis text-[12px] font-medium text-center text-white w-[78%] mx-auto">Immerse Yourself in Makro's Design Legacy: Request Access Today</div>
      </div>
      <div className="h-[637px] w-[379px] mx-auto z-[10] mt-[-272px]" style={formbanner}>
        <div className="text-white text-[24px] font-thin font-AvenirRoman pt-[18px] ">Fill the Form to</div>
        <div className="text-white text-[24px] font-medium leading-[1.2rem] font-AvenirRoman">Get Access</div>
        <div className="mt-[17px]">
          <AccessFormmb />
        </div>

      </div>
    </div>
  );
};

export default RequestFormmb;