import { useState, useEffect } from 'react';
import mklogo from "../resources/mklogo.png";
import txtlogo from "../resources/showcasetxt.png";
import showcaselogo1 from "../resources/showcaselogo1.png";
import arrowRight from "../resources/arrow-right.png";
import arrowBottom from "../resources/arrow-bottom.png";
import { Link } from 'react-router-dom';
const ExampleComponents = () => {
  const [showBorder, setShowBorder] = useState(true);
  const [showTxtLogo, setShowTxtLogo] = useState(true);
  const [showBottomLogo, setShowBottomLogo] = useState(false);

  useEffect(() => {
    // Toggle border every second
    const borderInterval = setInterval(() => {
      setShowBorder(prevState => !prevState);
    }, 1000);

    return () => {
      clearInterval(borderInterval);
    };
  }, []);

  const handleBottomLogoClick = () => {
    setShowBottomLogo(prevState => !prevState);
    console.log("showBottomLogo:", showBottomLogo);
  };

  return (
    <div>
      <div className="2xl:w-[555px] 2xl:h-[141px] 3xl:w-[525px] 3xl:h-[141px] md:w-[425px] md:h-[102px] bg-[#202020]  2xl:rounded-[43px] md:rounded-[31px] flex items-center relative overflow-hidden">
        <span className={`border border-r-2 border-t-0 border-b-0 border-l-0 ml-[82px] pr-[22px] transition-all duration-1000 ${showBorder ? '' : 'border-transparent'}`}>
          <img src={mklogo} alt="Home" className="defaultImage ml-[4px] 2xl:w-full md:w-[91%] 2xl:ml-[1px]  md:ml-[-42px]" />
        </span>
        <span className={`transition-all duration-1000 ${showTxtLogo ? 'ml-[44px]' : '-ml-[0px]'}`}>
          <img src={txtlogo} alt="Home" className="defaultImage 2xl:w-full  md:w-[81%]  2xl:ml[1px] md:ml-[-18px]  " />
        </span>
        <span className="absolute bottom-4 right-4 cursor-pointer" onClick={handleBottomLogoClick}>
          <img src={showBottomLogo ? arrowBottom : arrowRight} alt="Toggle" className="2xl:w-6 2xl:h-6  md:w-3 md:h-3 2xl:mt-[-64px] 2xl:mr-[44px]  md:mt-[-44px]" />
        </span>
      </div>
      <div className={`overflow-hidden ${showBottomLogo ? 'opacity-100' : 'opacity-0 hidden'}`}>
        <Link to="/Premises"><img src={showcaselogo1} alt="Home" className="defaultImage " /></Link>
      </div>
    </div>
  );
};

export default ExampleComponents;