import React, { Component } from 'react';
// import demo from "../resources/demofabric.png";
import apprealmb1 from "../resources/apprealmb1.png";
import apprealmb2 from "../resources/apprealmb2.png";
import apprealmb3 from "../resources/apprealmb3.png";
import apprealmb4 from "../resources/apprealmb4.png";
import apprealmb5 from "../resources/apprealmb5.png";
import apprealmb6 from "../resources/apprealmb6.png";

import apprealmb7 from "../resources/apprealmb7.png";
import apprealmb8 from "../resources/apprealmb8.png";
import apprealmb9 from "../resources/apprealmb9.png";
import apprealmb10 from "../resources/apprealmb10.png";
import apprealmb11 from "../resources/apprealmb11.png";
import apprealmb12 from "../resources/apprealmb12.png";


const Item = props => {
    return <div
        className="slider-item"
        style={{
            ":not(:last-of-type)": {
                marginRight: 12,
            }
        }}
        onClick={props.onClick}>
        {props.children}
    </div>
}


const FabricSlider1 = props => {
    const containerRef = React.useRef(null)
    const styles = {
        hidescroll: {
            width: '30%',
            overflow: 'hidden',
        },
        container: {
            width: '70%',
            overflowX: 'auto',
            paddingBottom: 20,
            marginBottom: -20,

        },
    }

    const goTo = (n) => {
        containerRef.current.scrollLeft = containerRef.current.children[0].children[n - 1].offsetLeft
    }

    const goToByElem = (elem) => {
        containerRef.current.scrollLeft = elem.offsetLeft;
    }

    const handleClick = (e) => {
        goToByElem(e.target)
    }

    return (
        <div className="slider-wrapper overflow-hidden  " style={styles.hidescroll} >
            <div className="slider-container" style={styles.container} ref={containerRef}>
                <ul className="slider-list ">
                    <Item onClick={handleClick}  >
                        <img src={apprealmb1} className='' />
                    </Item>
                    <Item onClick={handleClick} >
                        <img src={apprealmb2} className=' h-[162px]' />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb3} className=' h-[162px]' />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb4} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb5} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb6} />
                    </Item>

                </ul>
                <div className='w-full h-[22px]'>

                </div>
                <ul className="slider-list">
                    <Item onClick={handleClick}>
                        <img src={apprealmb7} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb8} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb9} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb10} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb11} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={apprealmb12} />
                    </Item>

                </ul>
            </div>
        </div>
    )

}

export default FabricSlider1;