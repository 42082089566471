import React, { useState } from 'react';
import loginlogo from "../resources/loginlogo.png";
import forgetbg1 from "../resources/Forgotbg.png";
import { Link } from 'react-router-dom';
// Functional Component
const ForgotForm = () => {
    const [isUserRegistered, setIsUserRegistered] = useState(true); // Initially assuming user is registered

    const forgotbg= {
        backgroundImage: `url(${forgetbg1})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        // Here you can check if the user is registered or not, based on your application logic
        // For now, let's assume the user is not registered
        setIsUserRegistered(false);
    };

    return (
        <div>
            <div className='w-full h-screen bg-black overflow-x-hidden 2xl:block md:block 3xl:block hidden sm:hidden '>
            <Link to="/"><div>
                    <img
                        src={loginlogo}
                        alt="logo"
                        className='ml-[122px] pt-[82px]'
                    />
                </div></Link>
                <   div className='2xl:w-[1974px] 2xl:h-[544px]   mx-auto 2xl:mt-[212px] md:mt-[112px] ' style={forgotbg}>
                    <div className='text-center font-Metropolils 2xl:text-[55px] md:text-[35px] font-thin text-white 2xl:leading-[3.9rem] md:leading-[2.9rem] 2xl:pt-[74px] md:pt-[24px]'>Enter your <span className='text-center font-Metropolils 2xl:text-[55px]  md:text-[35px]  font-normal text-white'>Registered Email </span><br /> to Change Password. </div>
                    <form onSubmit={handleSubmit}>
                        <div className='mt-[49px]'>
                            <input
                                type="text"
                                placeholder="Email"
                                name='email'
                                required
                                className="2xl:w-[503px] 2xl:h-[69px] md:h-[59px] md:w-[423px] md:h-[58px] 3xl:w-[503px] 3xl:h-[82px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white"
                            />
                        </div>
                        <div className='2xl:mt-[31px] md:pt-[31px] pb-[22px] '>
                            <button type="submit" className="2xl:w-[235px] md:w-[209px] 2xl:h-[70px] 3xl:h-[57px] md:h-[51px]  px-4 py-2 font-Metropolis font-medium 2xl:text-[27px] md:text-[17px]  text-white rounded-md gradient-button  ">Next</button>
                        </div>
                    </form>
                    <div className='2xl:pt-[1px] md:pb-[31px]'>
                    {!isUserRegistered && <p className="text-white text-center font-Metropolis ">This user is not registered with us yet. Kindly <Link to="/Requestform"><span className='underline'>request access.</span></Link></p>}
 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotForm;