import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import image1 from '../resources/ProductAppreal1.png';
import image2 from '../resources/ProductsApparel2.png';
import image3 from '../resources/ProductsApparel3.png';
import image4 from '../resources/ProductsApparel5.png';
import image5 from '../resources/ProductsApparel4.png';
import image6 from '../resources/ProductsApparelsix.png';
import image7 from '../resources/ProductAppreal7.png';
import image8 from '../resources/ProductAppreal8.png';
import image9 from '../resources/ProductAppreal9.png';
import image10 from '../resources/ProductAppreal10.png';
import image11 from '../resources/ProductAppreal11.png';
import image12 from '../resources/ProductAppreal12.png';
import cheronarrowleft from '../resources/chevron-left.svg';
import cheronarrowright from '../resources/chevron-right.svg';

const productImages = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12
];

function Slider({ images, startIndex, currentSlide, onIndicatorClick }) {
    return (
        <div className="carousel-content flex flex-wrap mx-auto rounded-[87px] w-[84%] 2xl:h-[1200px] overflow-x-auto scrollbar-custom relative mb-8 slide-transition">
            {images.map((image, index) => (
                <div key={index} className="w-1/3 p-4 image-container">
                    <Link to={`/product${startIndex + index}`}>
                        <img
                            src={image}
                            alt={`Image ${startIndex + index}`}
                            className="object-contain"
                        />
                    </Link>
                </div>
            ))}
            <div className="slider-indicators absolute bottom-1 left-1/2 transform -translate-x-1/2 flex mt-[42px]">
                {[...Array(2)].map((_, index) => (
                    <div
                        key={index}
                        className={`slider-indicator w-7 h-2 mx-1 rounded-[25px] ${currentSlide === index ? 'bg-[#9747ff]' : 'bg-gray-500'
                            } cursor-pointer`}
                        onClick={() => onIndicatorClick(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
}
function ProductsApparel() {
    const [currentSlider, setCurrentSlider] = useState(1);

    const sliders = [
        { images: productImages.slice(0, 6), startIndex: 6 },
        { images: productImages.slice(6), startIndex: 12 }
    ];

    const nextSlider = () => {
        setCurrentSlider(currentSlider === 1 ? 2 : 1);
    };

    const prevSlider = () => {
        setCurrentSlider(currentSlider === 1 ? 2 : 1);
    };

    const handleIndicatorClick = (index) => {
        setCurrentSlider(index === 0 ? 1 : 2);
    };

    return (
        <div className="carousel-container relative overflow-hidden mx-auto w-[89%]">
            <Slider
                images={sliders[currentSlider - 1].images}
                startIndex={sliders[currentSlider - 1].startIndex}
                currentSlide={currentSlider - 1}
                onIndicatorClick={handleIndicatorClick}
            />
            <button className="carousel-btn-left absolute top-1/2 left-0 transform -translate-y-1/2 text-white cursor-pointer text-white" onClick={prevSlider}>
                <img src={cheronarrowleft} alt="Previous" />
            </button>
            <button className="carousel-btn-right absolute top-1/2 right-0 transform -translate-y-1/2 text-white cursor-pointer text-white " onClick={nextSlider}>
                <img src={cheronarrowright} alt="Next" />
            </button>
        </div>
    );
}

export default ProductsApparel;
