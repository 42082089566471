import React from 'react';
import landinglogo from "../resources/landinglogo.png";
import mbbgbanner from "../resources/landingmbs1.jpg";
import mbbgbanner1 from "../resources/landingmbs2.jpg";
import venture from "../resources/ventures.svg";
import { Link } from 'react-router-dom';
function MobileLanding() {


    const mbbg1 = {
        backgroundImage: `url(${mbbgbanner})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative'

    };


    const mbbg2 = {
        backgroundImage: `url(${mbbgbanner1})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative'

    };

    return (
        <div>
            <div>
                <img src={landinglogo} className="w-[71%] md: mx-auto mt-[22px]" />
                <div className="text-[#969696] text-[14px] font-normal font-AvenirRoman mt-[4px] ">
                    Experience the <span className="text-black">Makro</span>
                </div>

            </div>
            <Link to="/Premises"> <div className='w-[83%] h-[387px] mx-auto mt-[12px] flex flex-col justify-center items-center' style={mbbg1}>
                <div className='font-Metropolis font-medium text-[31px] text-[#434343] text-center border-b-2 border-black'>Premises</div>
                <div className='font-Metropolis font-medium text-[11px] text-[#434343] text-center mt-[12px]'>Explore our establishment, cutting-edge infrastructure, certifications, and visionary approach to garment manufacturing. Click to witness excellence firsthand.</div>
            </div></Link>
            <Link to="/Showcasemb"> <div className='w-[88%] h-[387px] ml-[32px] mx-auto mt-[-62px] flex flex-col justify-center items-center' style={mbbg2}>
                <div className='font-Metropolis font-medium text-[31px] text-[#434343] text-center border-b-2 border-black'>Showcase</div>
                <div className='font-Metropolis font-medium text-[11px] text-[#434343] text-center mt-[12px] w-[74%]'>Experience our curated fashion pieces, intricate styles, and unique fabrics. Click to Unveil Makro's Masterpieces.</div>
            </div></Link>
            <div>
                <img src={venture} className='flex ml-[112px] w-[64%] mt-[-15px] '/>
                 <div className='w-full h-[24px] bg-white'></div>
            </div>
        </div>
    );
}

export default MobileLanding;