import React from 'react';
import loginlogo from "../resources/loginlogo.png";
import loginbg from "../resources/loginbg.png";
import { useState } from 'react';
import { Link } from 'react-router-dom';
const Login = () => {

  const login = {
    backgroundImage: `url(${loginbg})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset form fields
    setUsername('');
    setPassword('');

    // Show popup message (replace with your preferred notification method)
    alert("Enter the Correct Username and Password!");
  };


  return (
    <div className='log flex flex-row h-[1180px] bg-black'>
      <div className='flex flex-col w-[41%] h-[962px] bg-black mt-[57px]'>
        <div className='mt-[88px] ml-[122px] h-[444px]'>
          <img
            src={loginlogo}
            alt="logo"
          />
        </div>
        <div className='text-white text-thin  2xl:text-[55px] md:text-[45px] font-Metropolis w-[85%] text-left ml-[122px]  2xl:leading-[3.8rem] md:leading-[3.4rem]'>Get your hands on<br></br><span>
          the <span className='font-semibold'>Makro Exclusives</span></span>
          </div>
        <div className='text-[20px] font-AvenirRoman text-left w-[59%] ml-[122px] text-white mt-[25px]'>Indulge in Makro's top curated styles. Request access, and we'll swiftly provide your credentials. Immerse yourself in unparalleled craftsmanship and exquisite design.</div>
      </div>
      <div className='flex flex-col w-[59%] h-[1022px]  mt-[57px] mr-[35px]' style={login}>
        <div className='text-[65.31px] font-medium font-Metropolis text-center mt-[174px] text-white'>Login to Makro</div>
        <div className='font-Metropolis text-[22px] text-center mt-[18px]  text-[#686868] font-medium'>Unlock Makro's Exclusive Style Experience.</div>
        <form onSubmit={handleSubmit} className='mt-[129px]'>
          <div>
            <input
              type="text"
              placeholder='Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className='w-[591px] h-[82px] bg-[#282828] rounded-[12px] text-left pl-[59px] font-Metropolis font-medium text-white'
            />
          </div>
          <div className='mt-[24px]'>
            <input
              type="password"
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-[591px] h-[82px] bg-[#282828] rounded-[12px] pl-[59px] font-Metropolis font-medium text-white'
            />
          </div>
          <div className='flex flex-row mt-[38px]'>
            <div className='flex flex-col w-[50%] h-[144px] '>
              <div className="flex items-center  2xl:ml-[312px]  md:ml-[122px]">
                <input type='checkbox' id='rememberMe' className='mr-2 w-[22px] bg-white' />
                <label htmlFor='rememberMe' className='text-white font-Metropolis text-[#636363] text-[17px]'>Remember Me</label>
              </div>
            </div>
            <div className='flex flex-col w-[50%] h-[144px] '>
              <Link to="/Forgotpassword"><div className='text-white mr-[122px] font-Metropolis text-[#636363] text-[17px] '>Forgot Password?</div></Link>
            </div>
          </div>
          <div>
            <button type="submit" className='w-[591px] h-[82px] gradient-login rounded-[12px] text-[32px] font-Metropolis text-center'>Login</button>
          </div>
        </form>
        <Link to="/Requestform"><div className='text-[19px] font-Metropolis text-center text-white mt-[38px]'>Not a member yet? <span className='underline'>Request Access</span></div></Link>
      </div>
    </div>
  );
};

export default Login;