import fabgallery from "../resources/fabgallery.png";
import appgallery from "../resources/appgallery.png";
import "../App.css";
import app1 from "../resources/app1.png";
import app2 from "../resources/app2.png";
import app3 from "../resources/app3.png";
import app4 from "../resources/app4.png";
import app5 from "../resources/app5.png";
import app6 from "../resources/app6.png";
import fab1 from "../resources/fab1.png";
import fab2 from "../resources/fab2.png";
import fab3 from "../resources/fab3.png";
import fab4 from "../resources/fab4.png";
import fab5 from "../resources/fab5.png";
import fab6 from "../resources/fab6.png";
import home from "../resources/home.svg";
import homeblack from "../resources/homeblack.svg";
import crown from "../resources/crown.svg";
import land1 from "../resources/land1.png";
import land2 from "../resources/land2.png";
import land3 from "../resources/land3.png";
import land4 from "../resources/land4th.png";
import land5 from "../resources/land5.png";
import landall from "../resources/landiall.png";
import landall1 from "../resources/landiallmb.png";

import React from "react";
import { Link } from "react-router-dom";
import YourComponent from "./FabricSlider";
import SimpleSlider from "./FabricSlider";
import FabricGallerySlider from "./FabricSlider";
import Slider from "./FabricSlider";
import ExampleComponent from "./ShowcaseSection";
import mklogo from "../resources/mklogo.png"
import ExampleComponents from "./FabricSlider";
import Products from "./SliderContainer";
import Product3 from "./Product3";
import ProductsApparel from "./SliderContainer1";
import fotterlogo from "../resources/footelogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const Showcase = () => {

  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/Premises#sectionId1')
  };

  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Check if there's a hash in the URL indicating a specific section
    const hash = window.location.hash;
    if (hash) {
      // If there's a hash, scroll to the corresponding section
      const sectionId = hash.substring(1); // Remove the leading '#'
      scrollToSection(sectionId);
    } else {
      // If there's no hash, scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    // Clean up function
    return () => {
      // You can include any cleanup logic here if needed
    };
  }, []);

  return (
    <div className="2xl:block md:block hidden sm:hidden">
      <div className="h-auto w-full flex flex-col items-center justify-center bg-[#111111]">
        {/*  Front Page */}
        <div className=" 2xl:block hidden md:hidden">
          <div className="main-menu flex flex-row w-[94%] mx-auto h-auto gap-[44px] mt-[74px] 2xl:ml-[-12px]">

           

            <ExampleComponents />
            <div className="">
              <Link to="/" className="parentDiv ">
                <div>
                  <img src={home} alt="Home" className="defaultImage " />
                  <img src={homeblack} alt="Home Black" className="hoverImage" />
                </div>
              </Link>
            </div>

            <div className="">
              <div className="bg-[#202020] rounded-[30px] ease-in-out transition duration-1000 hover:bg-gradient-to-b hover:from-[#E7AC16] hover:to-[#FAD167]  w-[515px] h-[141px]">
                <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
                  <img src={crown} alt="Home" className=" hover:text-white" />
                  <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
                </div>
              </div>
            </div>

          </div>

        </div>


        <div className="medium w-[1440px] h-[222px]  md:block hidden 2xl:hidden ">
          <div className="flex flex-row gap-[24px] w-[87%] mx-auto mt-[52px]">
            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] ">
              <ExampleComponents/>
            </div>

            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
              <Link to="/">
                <div className="mt-[22px]">
                  <img src={home} alt="Home" className="defaultImage " />
                  <img src={homeblack} alt="Home Black" className="hoverImage" />
                </div>
              </Link>
            </div>

            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
              <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
                <img src={crown} alt="Home" className=" hover:text-white" />
                <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
              </div>
            </div>
          </div>
        </div>
       
       <div className="h-[244px] bg-black" id="sectionId1" >

       </div>

       <div className="w-[95%] mx-auto 2xl:ml-[37px]  2xl:mt-[1px] md:mt-[577px]">
  <div className="w-full h-[100vh] flex flex-row items-center justify-center 2xl:mt-[18px] 2xl:ml-[12px] md:mt-[-818px] md:ml-[12px] ">
            <Link to="/product5"  rel="noopener noreferrer">
              <img
                src={land3}
                alt="landingImage"
                className="2xl:w-auto 2xl:h-auto md:w-[98%] md:h-[194px] hover:-translate-y-[10px]  saturate-50 brightness-50 hover:brightness-125 hover:saturate-100 transition duration-1000 object-contain 2xl:ml-[7px] md:ml-[-4px] 2xl:mt-[32px] md:mt-[172px] "
              />
            </Link>
            <Link to="/product2"  rel="noopener noreferrer">
              <img
                src={land2}
                alt="landingImage"
                className="2xl:w-[335px] 2xl:h-[418px] md:w-[215px] md:h-[384px] 2xl:mt-[-92px] md:mt-[102px]  hover:-translate-y-[10px] saturate-50 brightness-50 hover:brightness-125 hover:saturate-100 transition duration-1000 object-contain"
              />
            </Link>
            <Link to="/product1"  rel="noopener noreferrer">
              <img
                src={land1}
                alt="landingImage"
                className="2xl:w-auto 2xl:h-auto md:w-[194px] 2xl:mt-[-192px] md:mt-[52px] hover:-translate-y-[10px]  transition duration-1000   object-contain ml-[12px]"
              />
            </Link>
            <Link to="/product3"  rel="noopener noreferrer">
              <img
                src={land4}
                alt="landingImage"
                className="2xl:w-auto 2xl:h-auto md:w-[224px]  hover:-translate-y-[10px] saturate-50 brightness-50 hover:brightness-125 hover:saturate-100 transition duration-1000 object-contain 2xl:mt-[-109px] md:mt-[92px]"
              />
            </Link>

            <Link to="/product4"  rel="noopener noreferrer">
              <img
                src={land5}
                alt="landingImage"
                className=" 2xl:w-auto 2xl:h-auto md:w-[98%] md:h-[168px] 2xl:mt-[71px] md:mt-[192px]  hover:-translate-y-[10px] saturate-50 brightness-50 hover:brightness-125 hover:saturate-100 transition duration-1000 object-contain"
              />
            </Link>


          </div>

          <div className=" w-full h-[20vh] flex items-center justify-center flex-col pb-[40px] relative 2xl:mt-[-142px] md:mt-[-122px]">

            <div className=" w-full h-[20vh] 2xl: text-[72px] 2xl:mt-[-82px] text-white font-Cinzel">
              Welcome to Makro
            </div>
            <div className="2xl:w-[30%] md:w-[57%] h-[20vh] text-[12px] text-white font-AvenirRoman">
              Explore Makro's Exotic Showcase featuring premium tailored pieces
              crafted in large volumes for<br></br> clients worldwide.
            </div>
          </div>
          <div className=" ">
            <img
              src={landall}
              alt="landingImage"
              className="2xl:w-[71%]  mx-auto 2xl:mt-[-359px] 2xl:block hidden md:hidden absolute   2xl:ml-[272px]   opacity-90"
            />
          </div>
          <div className="md:block 2xl:hidden  ">
            <img
              src={landall1}
              alt="landingImage "
              className="2xl:mt-[-244px] 2xl:ml-[252px] md:mt-[-244px] md:ml-[252px]"
            />
          </div>
        </div>

        {/* Fabric Gallery */}
        <div className="w-[95%] h-auto flex flex-col items-center justify-center gap-[20px] rounded-[60px] 2xl:mb-[100px] 2xl:mt-[82px] md:mb-[100px] md:mt-[142px]">
          <div className="w-[100%] h-[70vh] flex flex-row items-center justify-center gap-[20px]">
            <div className="2xl:w-[438px] 2xl:h-[720px] md:w-[424px] md:h-[620px] bg-[#050505] rounded-[59px] flex flex-col items-start justify-center text-[50px] xl:text-[75px] 2xl:leading-[6.9rem] md:leading-[70px] px-[20px] overflow-clip ">
              <div className="text-white 2xl:text-[87px] md:text-[67px] font-Metropolis font-light tracking-[-0.04em] ml-[52px] relative 2xl:mt-[-14px]">our<span className="border border-b-4 border-t-0 border-l-0 border-r-0 text-[#050505] text-transparent absolute 2xl:mt-[-35px]  2xl:ml-[-20px] 2xl:w-[444px] md:w-[274px] md:ml-[-17px] md:mt-[-17px]">n</span></div>
              <div className="text-[#2AF085] 2xl:text-[87px] md:text-[67px]  font-Metropolis font-medium tracking-[-0.04em] text-left leading-[0.9em] ml-[52px] mt-[-5px] gradient-fabric inline-block text-transparent ">fabric <br></br>gallery.</div>
              {/* <div className="2xl:text-[87px] md:text-[67px] font-Metropolis font-medium tracking-[-0.04em] bg-gradient-to-b from-[#2AF085] to-[#293d32] inline-block text-transparent bg-clip-text ml-[52px]">
                gallery.
              </div> */}

            </div>
            <div
              className="2xl:w-[1341px] 2xl:h-[720px] md:w-[1341px] md:h-[620px] bg-[#050505] rounded-[60px] flex items-center justify-center text-white text-[18px] xl:text-[26px] ml-[12px]"
              style={{
                backgroundImage: `url(${fabgallery})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <div className="2xl:w-[48%] md:w-[74%] font-AvenirRoman">
                {" "}
                Makro specialises in sourcing and crafting unique, intricate fabrics globally, with expertise in natural materials like Bamboo. Currently, we're innovating with Kapok and Banana fibers, exploring their potential for superior quality textiles.
              </div>
            </div>
          </div>
          <div className="2xl:mt-[1px] md:mt-[54px]  3xl:mt-[144px] w-full bg-[#171717] rounded-[40px] w-[98%] ">
            <Products />
          </div>
        </div>

        {/* Apparel Gallery */}

        <div className="w-[95%] h-auto flex flex-col items-center justify-center gap-[20px] bg-white rounded-[60px] mb-[100px]">
          <div className="2xl:w-[90%] md:w-[90%] flex flex-row items-center justify-center gap-[20px] 2xl:h-[70vh] md:h-[51vh] mt-[122px]">
            <div className="2xl:w-[398px] 2xl:h-[655px] md:w-[398px] md:h-[524px] mr-[18px] text-white bg-gradient-to-b from-[#9747FF] to-[#C69AFF] rounded-[57px] flex flex-col items-start justify-center text-[50px] xl:text-[75px] leading-[90px] px-[20px] overflow-clip">
              {/* <div className="text-white font-Metropolis text-[78px]">the________</div> */}
              <div className="text-white 2xl:text-[87px] md:text-[70px] font-Metropolis font-light tracking-[-0.04em] ml-[45px] relative">
                the
                <span className="border border-b-4 border-b-white border-t-0 border-l-0 border-r-0 text-transparent absolute mt-[-29px] ml-[-7px] w-[214px]">n</span>
              </div>
              <div className="2xl:text-[78px] md:text-[65px] ml-[42px] tracking-[-0.05em] font-Metropolis font-medium mt-[-18px] leading-[1.3em]">apparel</div>
              <div className="leading-[3.4rem] 2xl:text-[78px] md:text-[65px] ml-[42px] tracking-[-0.05em] font-Metropolis font-medium">gallery.</div>
            </div>
            <div
              className="2xl:w-[1219px] 2xl:h-[655px] md:w-[1219px] md:h-[535px] rounded-[60px] flex items-center justify-center  text-[#505050] text-[18px] xl:text-[26px]"
              style={{
                backgroundImage: `url(${appgallery})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <div className="w-[84%] 2xl:ml-[272px] md:ml-[22px]">
                <div className="2xl:w-[71%] md:w-full font-AvenirRoman text-[24px] ">
                  {" "}
                  Dive into the intricate detailing of our curated styles.
                  Experience premium quality work in our apparel gallery. <br></br>
                  <span className="text-[#CF980A]">
                    For access to our confidential catalogue, kindly request
                    permission from Makro Admin
                  </span>
                  .
                </div>
              </div>

            </div>
          </div>

          <div id="sectionId4" className="overflow-hidden">
            <ProductsApparel />
          </div>
        </div>
        {/* FOOTER SECTION */}


        <div className="hidden sm:flex h-[700px] w-full overflow-y-auto items-center justify-center">
          <div className="w-[97%] h-full flex flex-col items-center justify-center mt-[-122px]">
            <div className=" h-[20%] w-[95%] text-[35px] font-light text-white flex items-end justify-end flex-row gap-[20px]">
              <div className="w-[100%] h-[10%] flex items-center justify-center">
                <div className="w-full h-[1px] bg-[#7D7D7D]"></div>
              </div>
              <div className="w-[30%] h-[10%] flex items-center justify-center font-Cinzel">
                <img src={fotterlogo} alt="arrow" />
              </div>
            </div>
            <div className="h-[70%] w-full items-center justify-center flex flex-col mt-[42px] ">
              <div className="w-[60%] h-[80%] flex flex-row items-center justify-center ">
                <div className="w-[40%] h-full flex items-start justify-center text-white font-light flex-col gap-[20px] text-left text-[21px] font-AvenirRoman">
                  {/* <div>Home</div>
                  <div>About</div>
                  <div>Infrastructure</div>
                  <div>Service</div>
                  <div>Clients</div>
                  <div>Sustainability</div> */}
    <div className="font-AvenirRoman text-[23px] border-2 pr-[22px] pl-[22px] pt-[12px] pb-[12px] rounded-[22px] border-[#717171]">
    <button onClick={handleClick}>Get a Quote</button>
</div>
                </div>
                <div className="h-[50%] w-[20%] ">
                  <div className="bg-[#949393] w-[1px] h-full"> </div>
                </div>
                <div className="w-[40%] h-full flex items-center justify-center text-white font-light flex-col gap-[20px] text-left font-AvenirRoman text-[23px]">
                  <div className="flex flex-col ">
                    <div className="flex">
                      <span><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-8" /> {/* Address icon */}
                      </span><div className="text-left">
                        No.49, Tekic Tea Nagar,<br></br> Mudalipalayam, A, Sidco,
                        <br></br> Tirupur, Tamil Nadu. - 641602 <br></br>
                        <br></br>
                        <br></br>
                        <div className="flex">
                          <span><FontAwesomeIcon icon={faPhoneAlt} className="ml-[-47px]" /> {/* Phone icon */}
                          </span>+91 90430 38361
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[20%] w-full flex items-center justify-center text-[#C8C8C8] text-opacity-60 text-[14px] font-light mt-[-22px]">
                © 2024 Makro Fashions, All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
      <FabricGallerySlider/>
    </div> */}
    </div >

  );
};

export default Showcase;
