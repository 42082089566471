import React from 'react';
import { Link } from 'react-router-dom';
import home from "../resources/home.svg";
import homeblack from "../resources/homeblack.svg";
import crown from "../resources/crown.svg";
import prdouct1of1 from "../resources/Product1of1.png";
import product1of2 from "../resources/Product1of2.png";
import prdouct1of3 from "../resources/Product1of3.png";
import arrow from "../resources/chevron-left.png";
import ExampleComponents from './FabricSlider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const Product1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
}, []);
const navigate = useNavigate();


const handleClick = () => {
  navigate('/Showcase#sectionId1')
};
  return (
    <div className='bg-black mt-[-74px] pt-[54px]'>
       {/*  Front Page */}
       <div className=" 2xl:block hidden md:hidden 3xl:hidden bg-black">
         <div className="main-menu flex flex-row w-[94%] mx-auto h-auto gap-[44px] mt-[74px] 2xl:ml-[128px]">
           
     
<ExampleComponents/>
           <div className="">
            <Link to="/" className="parentDiv ">
          <div>
            <img src={home} alt="Home" className="defaultImage " />
            <img src={homeblack} alt="Home Black" className="hoverImage" />
          </div>
        </Link>
       </div>
           
            <div className="">
            <div className="bg-[#202020] rounded-[30px] w-[25%] h-[100%] ease-in-out transition duration-1000 hover:bg-gradient-to-b hover:from-[#E7AC16] hover:to-[#FAD167]  w-[585px] h-[141px]">
          <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
            <img src={crown} alt="Home" className=" hover:text-white" />
            <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
          </div>
        </div>
            </div>

         </div>

         </div>


         <div className="medium w-[1440px] h-[222px]  md:block hidden 2xl:hidden ">
         <div className="flex flex-row gap-[24px] w-[87%] mx-auto mt-[52px]">
           <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] ">
            <ExampleComponents/>
           </div>

           <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
            <Link  to="/">
           <div className="mt-[22px]">
            <img src={home} alt="Home" className="defaultImage " />
            <img src={homeblack} alt="Home Black" className="hoverImage" />
          </div>
          </Link>
           </div>

           <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
           <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
            <img src={crown} alt="Home" className=" hover:text-white" />
            <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
          </div>
           </div>
         </div>
     </div>


     
   <div className='overflow-hidden'>
     <div className="makebook w-[1782px] h-[222px] 3xl:block 2xl:hidden md:hidden ml-[52px]">
  <div className="flex flex-row gap-[24px] w-[97%] mx-auto mt-[52px]">
    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] ">
      <ExampleComponents />
    </div>

    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] bg-[#202020]">
    <Link  to="/">
           <div className="mt-[42px]">
            <img src={home} alt="Home" className="defaultImage " />
            <img src={homeblack} alt="Home Black" className="hoverImage" />
          </div>
          </Link>
    </div>

    <div className="flex flex-col w-[525px] h-[142px] rounded-[31px] bg-[#202020] ">
           <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
            <img src={crown} alt="Home" className=" hover:text-white" />
            <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
          </div>
           </div>
  </div>
</div>
</div>


       {/* Second Section */}
          <div className='flex flex-row w-[89%] mx-auto h-[790px] bg-black mt-[144px]'>
          <FontAwesomeIcon icon={faTimes} className='text-white text-3xl  cursor-pointer ml-[24px] ' onClick={handleClick} />

                 <div className='flex flex-col w-[50%] h-[512px] bg-black'>
                 <img src={prdouct1of1} className='w-[435px] h-[723px] mx-auto'/>

                 </div>
                 <div className='flex flex-col w-[50%] h-[512px] bg-black'>
                 <img src={product1of2} className='w-[435px] h-[723px] mx-auto'/>
             

                 </div>

          </div>

          
                   {/* Third Section */}

          <div className=' '>
             <div className='w-[1811px] h-[274px] design1 mx-auto w-[79%]'>
                <div className='text-[38px] font-Kamerik 105 W00 Bold text-center pt-[84px] text-white'>Cut Out Dress For Girls</div>
                
                <div className='text-[28px] font-AvenirRoman w-[79%] mx-auto text-left mx-auto text-center text-white'>
                Purple Jacquard Elegance: The Seamless Cutout Dress
</div>
             </div>
          </div>

        
       {/* Fourth Section */}
       <div className='flex flex-row w-[89%] mx-auto h-[790px] bg-black mt-[74px]'>
                 <div className='flex flex-col w-[50%] h-[512px] mx-auto bg-black'>
                 <img src={prdouct1of3} className='w-[736px] h-[749px] mx-auto mt-[-44px]'/>

                 </div>
                

          </div>
       {/* Fourth Section */}
       
    <div className='flex flex-row mt-[-127px] pb-[277px] bg-black'>
          <div className='flex flex-col w-[40%]  ml-[59px]'>
          <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center'>
  <span>Description</span>
  <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
</div>

<div className='text-[28px] font-AvenirRoman w-full text-white text-left mt-[35px]'>A sophisticated, intricately woven jacquard knit dress in subtle purple, featuring strategic cutouts that seamlessly converge at the waist, complemented by elegant puff shoulders.
</div>
      </div>
          <div className='flex flex-col w-[40%] ml-[48px] '>
          <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center'>
  <span>Details</span>
  <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
</div>
<div className='text-[28px] font-AvenirRoman text-white text-left mt-[21px]'>1. 100% Cotton</div>
<div className='text-[28px] font-AvenirRoman text-white text-left'>2. 200 GSM</div>
<div className='text-[28px] font-AvenirRoman text-white text-left'>3. Jacquard Knit</div>


          </div>

    </div>
       
    </div>
  );
};

export default Product1;


























// import React from 'react';
// import { Link } from 'react-router-dom';
// import home from "../resources/home.svg";
// import homeblack from "../resources/homeblack.svg";
// import crown from "../resources/crown.svg";
// import prdouct1of1 from "../resources/Product1of1.png";
// import product1of2 from "../resources/Product1of2.png";
// import prdouct1of3 from "../resources/Product1of3.png";
// import prdouct1of4 from "../resources/product1of4.png";
// import ExampleComponents from './FabricSlider';
// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// const Product1 = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
// }, []);
// const navigate = useNavigate();


// const handleClick = () => {
//   navigate('/Showcase#sectionId1')
// };

//   return (
//     <div className='bg-black'>
//        {/*  Front Page */}
//        <div className=" 2xl:block hidden md:hidden bg-black">
//          <div className="main-menu flex flex-row w-[94%] mx-auto h-auto gap-[44px] mt-[74px] 2xl:ml-[128px]">
           
     
// <ExampleComponents/>
//            <div className="">
//             <Link to="/" className="parentDiv ">
//           <div>
//             <img src={home} alt="Home" className="defaultImage " />
//             <img src={homeblack} alt="Home Black" className="hoverImage" />
//           </div>
//         </Link>
//        </div>
           
//             <div className="">
//             <div className="bg-[#202020] rounded-[30px] w-[25%] h-[100%] ease-in-out transition duration-1000 hover:bg-gradient-to-b hover:from-[#E7AC16] hover:to-[#FAD167]  w-[585px] h-[141px]">
//           <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
//             <img src={crown} alt="Home" className=" hover:text-white" />
//             <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
//           </div>
//         </div>
//             </div>

//          </div>

//          </div>

        
//          <div className="medium w-[1440px] h-[222px]  md:block hidden 2xl:hidden">
//          <div className="flex flex-row gap-[24px] w-[87%] mx-auto mt-[52px]">
//            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] ">
//             <ExampleComponents/>
//            </div>

//            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
//             <Link  to="/">
//            <div className="mt-[22px]">
//             <img src={home} alt="Home" className="defaultImage " />
//             <img src={homeblack} alt="Home Black" className="hoverImage" />
//           </div>
//           </Link>
//            </div>

//            <div className="flex flex-col w-[425px] h-[102px] rounded-[31px] bg-[#202020] ">
//            <div className="flex items-center justify-center w-full h-full gap-[10px] hover:scale-110 transition duration-500 ">
//             <img src={crown} alt="Home" className=" hover:text-white" />
//             <Link to="/Login"><div className="text-white text-[28px] font-Kamerik font-medium ">Insider Access</div></Link>
//           </div>
//            </div>
//          </div>
//      </div>

//        {/* Second Section */}
//        <div className='flex flex-row w-full h-[744px] bg-black mt-[141px]'>
//        <FontAwesomeIcon icon={faTimes} className='text-white text-3xl  cursor-pointer ml-[24px] ' onClick={handleClick} />

//         <div className='flex w-[94%] mx-auto bg-black gap-[18px]'>
//         <div className='flex flex-col w-[647px] h-[708px] '>
//         <img src={prdouct1of1} />

//         </div>
//          <div className='flex flex-col '>
//          <img src={product1of2} />
         
//          </div>
//         </div>
       
//        </div>
        
//        {/* THird Section */}
//           <div className=' '>
//              <div className='w-[1761px] h-[274px] design mx-auto w-[79%]'>
//                 <div className='text-[38px] font-Kamerik text-center pt-[84px] text-white'>THE NAME OF THE DESIGN GOES HERE</div>
                
//                 <div className='text-[28px] font-AvenirRoman w-[79%] mx-auto text-left mx-auto text-center text-white'>
//   Made with loose-fitting waffle fabric and a convenient<br></br> thumb hole, this long sleeve shirt.
// </div>
//              </div>
//           </div>
        
//        {/* Fourth Section */}
//        <div className='flex flex-row w-full h-[744px] bg-black mt-[141px]'>
//         <div className='flex w-[94%] mx-auto bg-black gap-[18px]'>
//         <div className='flex flex-col w-[780px] h-[744px] '>
//         <img src={prdouct1of3} />

//         </div>
        
//         </div>
       
//        </div>

//     <div className='flex flex-row mt-[77px] '>
//           <div className='flex flex-col w-[40%]  ml-[59px]'>
//           <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center'>
//   <span>Description</span>
//   <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
// </div>

// <div className='text-[28px] font-AvenirRoman w-full text-white text-left mt-[35px]'>Made with loose-fitting waffle fabric and<br></br> a convenient thumb hole, this long<br></br> sleeve shirt provides a relaxed fit while<br></br> keeping your hands warm.</div>
//       </div>
//           <div className='flex flex-col w-[40%] '>
//           <div className='text-left text-[28px] font-AvenirRoman text-white flex items-center'>
//   <span>Details</span>
//   <span className='h-[1px] bg-white w-[444px] ml-[22px] mt-[12px]'></span>
// </div>
// <div className='text-[28px] font-AvenirRoman text-white text-left mt-[21px]'>1.100% Cotton</div>
// <div className='text-[28px] font-AvenirRoman text-white text-left'>2.Weight - 280 GSM</div>
// <div className='text-[28px] font-AvenirRoman text-white text-left'>3.High-Density Print</div>
// <div className='text-[28px] font-AvenirRoman text-white text-left'>4.Reverse Wash only
// </div>

//           </div>

//     </div>
       
//     </div>
//   );
// };

// export default Product1;