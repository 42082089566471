import React, { Component } from 'react';
// import demo from "../resources/demofabric.png";
import fabricmb1 from "../resources/fabricmb1.png";
import fabricmb2 from "../resources/fabricmb2.png";
import fabricmb3 from "../resources/fabricmb3.png";
import fabricmb4 from "../resources/fabricmb4.png";
import fabricmb5 from "../resources/fabricmb5.png";
import fabricmb6 from "../resources/fabricmbsix.png";
import fabricmb7 from "../resources/fabricmb7.png";
import fabricmb8 from "../resources/fabricmb8.png";
import fabricmb9 from "../resources/fabricmb9.png";
import fabricmb10 from "../resources/fabricmb10.png";
import fabricmb11 from "../resources/fabricmb11.png";
import fabricmb12 from "../resources/fabricmb12.png";
import fabricmb13 from "../resources/fabricmb13.png";
import fabricmb14 from "../resources/fabricmb14.png";
import fabricmb15 from "../resources/fabricmb15.png";
import fabricmb16 from "../resources/fabricmb16.png";
import fabricmb17 from "../resources/fabricmb17.png";
import fabricmb18 from "../resources/fabricmb18.png";

const Item = props => {
    return <div
        className="slider-item"
        style={{
            ":not(:last-of-type)": {
                marginRight: 12,
            }
        }}
        onClick={props.onClick}>
        {props.children}
    </div>
}


const FabricSlider = props => {
    const containerRef = React.useRef(null)
    const styles = {
        hidescroll: {
            width: '30%',
            overflow: 'hidden',
        },
        container: {
            width: '70%',
            overflowX: 'auto',
            paddingBottom: 20,
            marginBottom: -20,

        },
    }

    const goTo = (n) => {
        containerRef.current.scrollLeft = containerRef.current.children[0].children[n - 1].offsetLeft
    }

    const goToByElem = (elem) => {
        containerRef.current.scrollLeft = elem.offsetLeft;
    }

    const handleClick = (e) => {
        goToByElem(e.target)
    }

    return (
        <div className="slider-wrapper" style={styles.hidescroll}>
            <div className="slider-container" style={styles.container} ref={containerRef}>
                <ul className="slider-list ">
                    <Item onClick={handleClick} style={fabricmb1} >
                        <img src={fabricmb1} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb2} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb3} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb4} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb5} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb6} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb7} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb8} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb9} />
                    </Item>
                </ul>
                <div className='w-full h-[22px]'>

                </div>
                <ul className="slider-list">
                    <Item onClick={handleClick}>
                        <img src={fabricmb10} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb11} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb12} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb13} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb14} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb15} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb16} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb17} />
                    </Item>
                    <Item onClick={handleClick}>
                        <img src={fabricmb18} />
                    </Item>
                </ul>
            </div>
        </div>
    )

}

export default FabricSlider;