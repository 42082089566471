import React, { useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import mblogo from "../resources/mobilelogo.png";
import menulogo from "../resources/menulogo.png";
import hamburg from "../resources/hamburg.svg";
import { Link } from 'react-router-dom';
// import ShowcaseHamburg from './SHowcaseHamburg';
import PremisesHamburg from './PremisesHamburg';
const MobileNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setIsOpen(false);
        }
    };
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <nav className="nav-mobile sm:w-[392px] ">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-[12px]">
                <div className="flex items-center justify-between h-16">
                    <div className="block sm:hidden">
                        <Link to="/landing1"><div className=' font-Cinzel text-[19px] text-left leading-[95%] pt-[12px] pl-[12px] '>Makro<br></br> Fashions</div></Link>

                    </div>
                    <div className="relative">
                        <div className="absolute top-0 right-10 z-10">
                            <div className="flex items-center">
                                <div className="flex-shrink-0" onClick={toggleMenu}>
                                    <img src={hamburg} alt="Hamburger" />
                                </div>
                            </div>
                        </div>
                        <div className={`absolute top-40 right-14 z-0 transition-transform duration-500 ${isOpen ? 'slide-in' : 'slide-out'}`} >
                            <div className="mx-auto">
                                {isOpen && <PremisesHamburg />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    );
};

export default MobileNavbar;