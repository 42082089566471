import React, { useState,useRef } from 'react';

import emailjs from '@emailjs/browser';
import {  useNavigate } from 'react-router-dom'; // Import useHistory hook

// import { Link } from 'react-router-dom';
function AccessForm() {

  const navigate = useNavigate(); // Initialize useHistory hook
  const form = useRef();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ib9gtgl', 'template_9i8eicj', form.current, {
        publicKey: 'I4lBD175Tz08-E5cr',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setShowSuccessMessage(true);
          form.current.reset();
          // Navigate to another component upon successful form submission
          navigate('/thankyou'); // Specify the path of the component you want to navigate to
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="mx-auto 2xl:h-[779px] 3xl:h-[579px] overflow-y-hidden">
     <form ref={form} onSubmit={sendEmail} className="space-y-4">
      <div>
        <input
          type="text"
          placeholder="Username"
          name='from_name'
          required
          className="2xl:w-[503px] 2xl:h-[70px] md:w-[423px] md:h-[49px] 3xl:w-[503px] 3xl:h-[52px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white"
        />
      </div>
      <div>
        <input
          type="email"
          placeholder="Email"
          name="from_email"
          required
          className="2xl:w-[503px] 2xl:h-[70px] md:w-[423px] md:h-[49px] 3xl:w-[503px] 3xl:h-[52px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white >"
        />
      </div>
      <div>
       
      <div className="flex space-x-4 2xl:ml-[94px] md:ml-[38px]">
      <label className='flex items-center bg-[#282828] 2xl:h-[70px] md:h-[49px] 2xl:w-[154px] md:w-[129px] 3xl:w-[153px] 3xl:h-[52px] rounded-[10px]' >
        <input
          type="radio"
          name="to_selector"
          value="Buyer"
          
          required
          className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
        />
        <span className="ml-2 font-Metropolis font-medium text-[17px] text-[#797979]">Buyer</span>
      </label>
      <label className='flex items-center bg-[#282828] 2xl:h-[70px] md:h-[49px] 2xl:w-[154px] md:w-[129px] 3xl:w-[153px] 3xl:h-[52px] rounded-[10px]'>
        <input
           type="radio"
           name="to_selector"
           value="Seller"
           required
          className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
        />
        <span className="ml-2 font-Metropolis font-medium text-[17px] text-[#797979]">Seller</span>
      </label>
      <label className='flex items-center bg-[#282828] 2xl:h-[70px] md:h-[49px] 2xl:w-[154px] md:w-[129px] 3xl:w-[153px] 3xl:h-[52px] rounded-[10px]'>
        <input
        type="radio"
        name="to_selector"
        value="Other"
        required
          className='ml-[22px] font-Metropolis font-medium text-[14px] text-[#797979]'
        />
        <span className="ml-2 font-Metropolis font-medium text-[17px] text-[#797979]">Other</span>
      </label>
    </div>
      </div>
      <div>
        <input
          type="text"
          placeholder="Company Name"
          name="to_companyname"
          required
          className="2xl:w-[503px] 2xl:h-[70px] md:w-[423px] md:h-[49px] 3xl:w-[503px] 3xl:h-[52px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979]  text-white      />
          "
        />
      </div>
      <div>
        <input
          type="tel"
          placeholder="Phone Number"
          name="to_phonenumber"
          required
          className="2xl:w-[503px] 2xl:h-[70px] md:w-[423px] md:h-[49px] 3xl:w-[503px] 3xl:h-[52px] rounded-[10px] bg-[#282828] pl-[21px] font-Metropolis font-medium text-[14px] text-[#797979] text-white />   "
        />
      </div>
      <div>
        <textarea
          placeholder="Comments"
          name="to_comments"
          required
          rows="4" 
          className="2xl:w-[503px] 2xl:h-[189px] md:w-[423px] md:h-[148px] 3xl:w-[503px] 3xl:h-[117px] rounded-[10px] bg-[#282828] pl-[21px] 2xl:pt-[12px] md:pt-[12px]  font-Metropolis font-medium text-[14px] text-[#797979] text-white> "
        ></textarea>
      </div>
      
      <button type="submit" className="2xl:w-[279px] md:w-[209px] 2xl:h-[70px] 3xl:h-[57px] md:h-[51px]  px-4 py-2 font-Metropolis font-medium 2xl:text-[27px] md:text-[17px]  text-white rounded-md gradient-button  ">Request Access</button>
      
    </form>
    
  
  </div>
  );
}

export default AccessForm;