import React, { useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import mblogo from "../resources/mobilelogo.png";
import menulogo from "../resources/menulogo.png";
import hamburg from "../resources/hamburg.svg";
import { Link } from 'react-router-dom';
import ShowcaseHamburg from './SHowcaseHamburg';
const MobileNavbarShowcase = () => {
    const [isOpen, setIsOpen] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setIsOpen(false);
        }
    };
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <nav className="nav-mobile1">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-[12px]">
                <div className="flex items-center justify-between h-16">
                    <div className="block sm:hidden">
                        <Link to="/landing1"><div className='text-white font-Cinzel text-[19px] text-left leading-[95%] pt-[12px] pl-[12px] '>Makro<br></br> Fashions</div></Link>

                    </div>
                    <div className="relative">
                        <div className="absolute top-0 right-10 z-10">
                            <div className="flex items-center">
                                <div className="flex-shrink-0" onClick={toggleMenu}>
                                    <img src={hamburg} alt="Hamburger" />
                                </div>
                            </div>
                        </div>
                        <div className={`absolute top-40 right-14 z-0 transition-transform duration-500 ${isOpen ? 'slide-in' : 'slide-out'}`} >
                            <div className="mx-auto">
                                {isOpen && <ShowcaseHamburg />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Transition
                show={isOpen}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {(ref) => (
                    <div ref={ref} className="nav-menu w-[265px] h-[588px] mx-auto">
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <img src={menulogo} className="mx-auto mt-[27px]" alt="Menu Logo" />
                            <div className="w-[91px] h-[1px] bg-red-200 mx-auto"></div>
                            <div
                                className="block px-3 py-7 ml-[42px] text-[15px] rounded-md text-base font-medium font-AvenirRoman text-left text-[#1e1e1e] hover:text-gray-900 hover:bg-gray-50"
                            >
                                Home
                            </div>
                            <div
                                className="block px-3 py-7 ml-[42px] text-[15px] rounded-md text-base font-medium font-AvenirRoman text-left text-[#1e1e1e] hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => scrollToSection('establishment')}
                            >
                                Establishment
                            </div>
                            <div
                                className="block px-3 py-7 ml-[42px] text-[15px] rounded-md text-base font-medium font-AvenirRoman text-left text-[#1e1e1e] hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => scrollToSection('infrastructure')}
                            >
                                Infrastructure
                            </div>
                            <div
                                className="block px-3 py-7 ml-[42px] text-[15px] rounded-md text-base font-medium font-AvenirRoman text-left text-[#1e1e1e] hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => scrollToSection('sustainability')}

                            >
                                Sustainability
                            </div>
                            <div
                                className="block px-3 py-7 ml-[42px] text-[15px] rounded-md text-base font-medium font-AvenirRoman text-left text-[#1e1e1e] hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => scrollToSection('sustainability')}

                            >
                                Clients
                            </div>
                            <button
                                onClick={() => scrollToSection('contact')} // Adjust ID to match your contact section ID
                                className="contactButton font-AvenirRoman text-white"
                            >
                                Contact
                            </button>
                        </div>
                    </div>
                )}
            </Transition> */}
        </nav>
    );
};

export default MobileNavbarShowcase;