import React from 'react';
import showcasemainlogo from "../resources/showcasemainlog.svg";
import whitelog from "../resources/whitelogo.svg";
import insidelogo from "../resources/insidelogo.svg";
import { Link } from 'react-router-dom';
import banner from "../resources/showcaseframe.png";
const PremisesHamburg = () => {
    const showcase = {
        backgroundImage: `url(${banner})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };
    return (
        <div>
            <div className="w-[257px] h-[407px]  mx-auto mt-[25px]" style={showcase}>

                <img src={showcasemainlogo} alt="Mobile Logo" className='mx-auto pt-[77px]' />
                <div className='w-[201px] h-[37px] rounded-[35px] bg-[#252323] mx-auto mt-[25px]'>
                    <Link to="/landing1"><div className='flex flex-row ml-[-22px]'>
                        <div className='flex flex-col w-[50%] h-full  items-center justify-end mt-[12px]' style={{ borderRight: '1px solid white' }}>
                            <span className='self-end mr-[12px]' ><Link to="/landing1"><img src={whitelog} alt="Mobile Logo" className='' /></Link></span>

                        </div>
                        <div className='flex flex-col w-[50%] h-full mt-[12px]' >
                            <div className='text-white mr-[52px] font-Metropolis text-[11px]'>Home</div>
                        </div>

                    </div>
                    </Link>

                    <div className='switch'>
                        <div className='mx-auto text-white mt-[32px] text-[9px] font-AvenirRoman'>switch to</div>
                        <div className='w-[202px] h-[41px] bg-[#181818] rounded-[22px] flex flex-row mt-[9px]' style={{ border: '0.742647px solid rgba(255, 255, 255, 0.42)', borderRadius: '45px' }}>
                            <div className='flex flex-col w-[50%] h-full text-[11px] rounded-[45px] font-AvenirRoman pt-[12px] text-white '>
                                <Link to="/Showcasemb"> SHOWCASE</Link>
                            </div>
                            <div className='flex flex-col w-[50%] h-full bg-white rounded-[45px] text-[11px] font-AvenirRoman pt-[12px] text-black'>
                                PREMISES

                            </div>

                        </div>
                    </div>
                    {/* 
                    <div className='w-[201px] h-[39px] gradient-inside flex flex-row mt-[17px]'>
                        <div className='flex flex-col w-[30%] '>
                            <img src={insidelogo} className='w-[32%] mt-[12px] ml-[32px] ' />
                        </div>
                        <div className='flex flex-col w-[70%] text-[14px] text-white font-Metropolis mr-[22px] mt-[11px]'>
                            <Link to="/Loginmb">Insider Access</Link>
                        </div>

                    </div> */}

                    <div className='text-[9px] text-[#c8c8c8] mt-[24px]'>
                        © 2024, All Rights Reserved.
                    </div>


                </div>
            </div>
        </div>
    );
};

export default PremisesHamburg;